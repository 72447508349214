import React, { useContext, useEffect, useRef, useState } from 'react'
import './chathomepage.styles.scss'
import { BackButton, ChatList } from '../../components'
import { PiMagnifyingGlass } from 'react-icons/pi'
import { animated, useSpring } from '@react-spring/web'
import { StateContext } from '../../context/stateContext'
import { noMessage } from '../../assets/images'


const ChatHomePage = () => {
    const {darkMode} = useContext(StateContext)
    const {chatHomePageData, chatListHandler, chatsList, setChatsList} = useContext(StateContext)
    const [selectedTab, setSeletedTab] = useState('All')
    const [showInput, setShowInput] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [loading, setLoading] = useState(false)
    const [timer, setTimer] = useState(undefined)
    const apiFired = useRef()
    const drawerAnimation = useSpring({
        transform: showInput ? 'translateX(0%)' : 'translateX(100%)',
        config: { tension: 100, friction: 15 }, // Spring-like configuration
    });

    useEffect(()=>{
        if(searchText.length > 0){
            setLoading(true)
            if(timer){
                clearTimeout(timer)
            }
            let timeId = setTimeout(()=>{
                const filterResults = chatHomePageData.filter(item => item?.participantDetails?.fullName?.toLocaleLowerCase().includes(searchText?.toLocaleLowerCase()) || item?.nickName?.toLocaleLowerCase().includes(searchText?.toLocaleLowerCase()))
                setChatsList(filterResults)
                setLoading(false)
            }, 1000)
            setTimer(timeId)
        }
        else{
            setChatsList(chatHomePageData)
        }
    // eslint-disable-next-line 
    },[searchText])

    const tabChangeHandler = (text) =>{
        setSeletedTab(text)
        if(text === 'Read'){
            const filteredData = chatHomePageData.filter(item => item?.lastMessage?.isRead === true)
            setChatsList(filteredData)
            
        }
        else if(text === 'Unread'){
            const filteredData = chatHomePageData.filter(item => item?.lastMessage?.isRead === false)
            setChatsList(filteredData)
        }
        else{
            setChatsList(chatHomePageData)
        }
    }

    useEffect(()=>{
        if(!apiFired.current){
            chatListHandler()
            apiFired.current = true
        }
        // eslint-disable-next-line
    },[])

    return (
        <div className={`${darkMode ? 'dark-mode' : ''} px-10 `}>
            <div className='overflow-hidden chathomepage-container d-flex space-between items-center my-20 px-10'>
                <BackButton size={24} style={{marginLeft:-15}}/>
                <h2 className={`fw-500 px-10 dark-mode-text ${showInput ? 'mute' : ''}`}>Message</h2>

                <animated.div className={`${showInput ? '' : "mute"}`} style={drawerAnimation}>
                    <input value={searchText} onChange={(e)=>setSearchText(e.target.value)} style={{width:'100%', height:30}}/>
                </animated.div>
                <PiMagnifyingGlass size={30} onClick={()=>setShowInput(!showInput)}/>
            </div>

            <p className='px-10 dark-mode-text'>Recent Chats</p>
            
            <div className='px-10 d-flex my-20 chips-container'>
                <p onClick={()=>tabChangeHandler('All')} className={`cursor-pointer ${selectedTab === 'All' ? 'active' : ""}`}>All</p>
                <p onClick={()=>tabChangeHandler('Unread')} className={`cursor-pointer ${selectedTab === 'Unread' ? 'active' : ""}`}>Unread</p>
                <p onClick={()=>tabChangeHandler('Read')} className={`cursor-pointer ${selectedTab === 'Read' ? 'active' : ""}`}>Read</p>
            </div>

            {loading ?
                // show loading 
                <p>Loading...</p>
                :
                <div className='px-10'>
                    {chatsList.map(({id, theme, participantDetails, lastMessage, isActive, nickName})=>{
                        if(!lastMessage){
                            console.log('returning  1')
                            return null
                        }
                        if(!lastMessage?.message && !lastMessage?.image){
                            console.log('returning  2')
                            return null
                        }
                        return (
                            <ChatList
                                key={id}
                                isActive={isActive}
                                userId={participantDetails?.id}
                                userDetailsId={participantDetails?.userDetailsId}
                                image={participantDetails?.profileImage}
                                username={participantDetails?.username}
                                nickName={nickName}
                                fullName={participantDetails?.fullName}
                                message={lastMessage?.message}
                                lastMessageFrom={lastMessage?.sender}
                                isRead={lastMessage?.isRead}
                                isReadByReceiver={lastMessage?.isReadByReceiver}
                                unreadCount={lastMessage?.unreadCount}
                                timestamp={lastMessage?.timestamp}
                                theme={theme}
                                isGuide={participantDetails?.isGuide}
                            />
                        )
                    })}
                </div>
            }
            {!loading && chatsList.length === 0 &&
                <div className='d-flex column content-center items-center my-10'>
                    <img style={{height:200, width:200, marginTop:40}} src={noMessage} alt='No Message' /> 
                    <p className='dark-mode-gray-text'>No Message</p>
                </div>
            }
        </div>
  )
}

export default ChatHomePage