import React, { useContext, useRef } from 'react'
import { BsX } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { ButtonComponent } from '../../components'
import { createGuide, storeIdentityVerification } from '../../utils/service'
import { useNotify } from '../../components/Toast'
import { StateContext } from '../../context/stateContext'
import { IndiaGateImage, logo } from '../../assets/images'

const Wrapper = ({image, desktopImage, slideIndex, title, children, descText, nextButtonText, nextButtonUrl, identityHandler, submit=false, lineLength=4}) => {
  const navigate = useNavigate()
  const {isTourist, darkMode, userForm, identityVerificationForm} = useContext(StateContext)
  const { notify } = useNotify()
  const isApiFired = useRef()

  const validateEmail = (inputEmail) => {  
    const emailRegex = /^[a-zA-Z0-9._]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;  
    return emailRegex.test(inputEmail);  
  };  

  const isValidPassword = (password) =>{
    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password)
  }

  function validateUsername(username) {
      const regex = /^[a-zA-Z0-9_]+$/;
      if (!regex.test(username)) {
        return true
  }
}

  const submitHandler = () => {
    if(!submit){
      // if we are navigating from identiy verification screen to bank detail screen first check if the adhaar card and pan card are valid
      if(nextButtonUrl === '/Profile-Verification/bank-details-verification'){
        let adhaarRegex = /^\d{4}\s?\d{4}\s?\d{4}$/
        let panCardRegex = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/
        if(!adhaarRegex.test(identityVerificationForm?.adhaarCard) || identityVerificationForm?.adhaarCard?.length < 12){
          return notify('please enter valid adhaar card number')
        }
        if(!panCardRegex.test(identityVerificationForm?.panCard) || identityVerificationForm?.panCard?.length < 10){
          return notify('please enter valid pan card number')
        }
      }
      if(nextButtonUrl === '/verification-wizard/personalize-account'){
        if(userForm?.country?.length <= 2){
          return notify(`Please provide valid country name`)
        }
        if(userForm?.state?.length <= 2){
          return notify(`Please provide valid state name`)
        }
        if(userForm?.city?.length <= 2){
          return notify(`Please provide valid city name`)
        }
        if(!isTourist && userForm?.cityOfService?.length <= 2){
          return notify('please provide valid service provided location')
        }
        if(userForm?.profession?.length <= 2){
          return notify(`Please provide valid profession details`)
        }
        if(userForm?.languages[0]['language'].length <= 3){
          return notify(`Please provide valid languages`)
        }
        if(userForm?.hobbiesAndInterest?.length <= 4){
          return notify(`Please provide valid hobbies and interest`)
        }
        if(!isTourist && userForm?.isGuide?.length <= 0){
          return notify('please provide your availability as guide.')
        }
      }
      if(nextButtonUrl === '/verification-wizard/personal-information'){
        if(userForm?.contact?.slice(0, 2) !== '91' && !(10 < userForm?.contact?.length && userForm?.contact?.length < 18)){
          return notify(`Please provide valid contact information`)
        }
        if(userForm?.contact?.slice(0, 2) === '91' && userForm?.contact.length !== 12){
          return notify('Please enter valid contact number.')
        }
        if(!userForm?.contactVerified){
          return notify('Please verify your contact number.')
        }
        if(!validateEmail(userForm?.email)){
          return notify('Please enter valid email.')
        }
        // if(!userForm?.emailVerified){
        //   return notify('Please verify your email.')
        // }
      }
      return navigate(nextButtonUrl)
    }

    
    if(identityHandler && !isApiFired.current){
      isApiFired.current = true
      storeIdentityVerification(identityVerificationForm).then(res => {
        notify('Form Submitted 🚀, Redirecting...')
        setTimeout(()=>{
          navigate(nextButtonUrl)
        }, 2000)
      }).catch(err => {
        notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
      }).finally(()=>{
        isApiFired.current = false
      })
      return
    }

    if(userForm?.contact.length < 10 || userForm?.email.length < 10){
      notify('Please complete contact verification process.')
      setTimeout(()=>{
        navigate('/Verification-wizard/contact-verification')
      }, 1000)
      return
    }

    if(!userForm?.contactVerified){
      notify('Please verify your information.')
      setTimeout(()=>{
        navigate('/Verification-wizard/contact-verification')
      }, 1000)
      return
    }

    if(userForm?.username.length < 5){
      return notify('please write a valid username.')
    }
    if(validateUsername(userForm?.username)){
      return notify('please write a valid username.')
    }
    if(!isValidPassword(userForm?.password)){
      return notify('Please make sure password meets all the requirements.')
    }
    if(userForm?.whatsappNumber.length < 10){
      return notify('Please provide valid whatsapp number.')
    }
    if(!userForm?.ageChecked || !userForm?.termsChecked){
      return notify('please check in the required boxes.')
    }
    if(!isApiFired?.current){
      createGuide(userForm).then(res => {
        isApiFired.current = true
        notify('Account Created 🚀, Redirecting...')
        setTimeout(()=>{
          navigate(nextButtonUrl)
        }, 2000)
      }).catch(err => {
        notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
      }).finally(()=>{
        isApiFired.current = false
      })
    }
  }

  return (
    <>
      <div className={`${darkMode ? 'dark-mode' : ""}`}>
        <div className='contact-verification-container container'>
          <img src={logo} alt='city2guide logo' className='position-absolute hide-on-mobile' style={{height:100, width:100, top:10, left:10}}/>
          <div className='responsive-image-container'>
            {/* img */}
            <img className='contact-card' src={image} alt='Personalize your account' />
            <img className='indiaGate' src={desktopImage || IndiaGateImage} alt='' />
          </div>

          <div className='responsive-content-container'>
            {/* close icon */}
            <BsX onClick={()=>navigate('/Signup')} className='close-icon hide-on-desktop'/>
            
            {/* sliding lines */}
            <div className='sliding-lines-container'>
                {Array.from(Array(lineLength).keys()).map(number => {
                    return(
                        <div key={number} className='sliding-line'>
                            {number < slideIndex ?
                                <span className='active' style={{width:'100%'}} />
                                :
                                <span className='active' style={{width: number === slideIndex ? '10%' : '0%'}} />
                            }
                        </div>
                    )
                })}
            </div>

            {/* Personal Information : text */}
            <h1 className='verification-text'>{title}</h1>
            {descText &&
              <p className='desc-text'>{descText}</p>
            }
            {children}
            

            {/* back & save and continue buttton */}
            <div className='back-save-buttons-container'>
              <ButtonComponent onClick={()=>navigate(-1)} title={'Back'} wrapperContainerStyle={{width:"35%", backgroundImage:"linear-gradient(to right bottom, whitesmoke  41%, whitesmoke  100%)", opacity:0.9}} buttonTextColor='black' />
              <ButtonComponent onClick={()=>submitHandler()} title={nextButtonText} wrapperContainerStyle={{width:"45%"}}/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Wrapper