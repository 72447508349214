import React, { useContext, useEffect, useRef, useState } from 'react'
import '../UpdateUsername/updateusername.styles.scss'
import { StateContext } from '../../context/stateContext'
import { BackButton, ButtonComponent, OtpComponent } from '../../components'
import { fetchNumberPrivacy, generateOtp, updateContact, updateLocalStorage, updateNumberPrivacy, verifyOtp } from '../../utils/service'
import { useNotify } from '../../components/Toast'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const UpdateUsername = () => {
    const {darkMode, userInfo, setUserInfo} = useContext(StateContext)
    const [contact, setContact] = useState('')
    const [contactUpdated, setContactUpdated] = useState(false)
    const [showContact, setShowContact] = useState(false)
    const [loading, setLoading] = useState(true)
    const [showUpdate, setShowUpdate] = useState(false)
    const [smsTimer, setSmsTimer] = useState(0); // Timer for SMS
    const [hideGetOtpButton, setHideGetOtpButton] = useState(false);
    const smsApiFired = useRef()
    const [state, setState] = useState({
        otp1:'',
        otp2:'',
        otp3:'',
        otp4:''
    })
    const { notify } = useNotify()

    const fireOtpHandler = () => {
        if (smsApiFired.current) {
            return;
        }

        if(contact.slice(0, 2) !== '91' && !(10 < contact.length && contact.length < 18)){
            return notify(`Please provide valid contact information`)
        }

        if(contact.slice(0, 2) === '91' && contact.length !== 12){
            return notify('Please enter valid contact number.')
        }

        if(contact === userInfo?.contact){
            return notify("Current phone number and new number can't be same")
        }

        smsApiFired.current = true;
        // true means we are firing for reset otp password from dlt as we have to switch the apis in backend for the message template.
        generateOtp(contact, true)
            .then(res => {
                notify("OTP sent successfully!");
                setHideGetOtpButton(true);
                setSmsTimer(119); // Start the SMS timer
            })
            .catch(err => {
                notify(err.response && err.response.data.detail ? err.response.data.detail : err.message);
            })
            .finally(() => {
                smsApiFired.current = false;
            });
    };

    useEffect(() => {
        if (smsTimer > 0) {
            const timerId = setTimeout(() => setSmsTimer(smsTimer - 1), 1000);
            return () => clearTimeout(timerId);
        }
    }, [smsTimer]);

    const verifyOtpHandler = () => {
        if(!hideGetOtpButton){
            return notify('Please request for OTP first.')
        }
        if (!Object.values(state).every(val => val.length > 0)) {
            return notify('Please enter a valid OTP');
        }
        verifyOtp(contact, state, true)
            .then(res => {
                notify("OTP verified successfully!");
                setShowUpdate(true)
            })
            .catch(err => {
                notify(err.response && err.response.data.detail ? err.response.data.detail : err.message);
            });
    };

    const updateHandler = ()=>{
        if(contact.length < 10){
            return notify('please enter a valid phone number')
        }
        updateContact(contact).then(res => {
            notify('Contact updated')
            setContactUpdated(true)
            setUserInfo(updateLocalStorage('contact', contact))
        }).catch(err =>{
            notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
        })
    }

    useEffect(()=>{
        setLoading(true)
        fetchNumberPrivacy().then(res => {
            setShowContact(res.data.privacyStatus)
            setTimeout(()=>{
                setLoading(false)
            }, 1000)
        }).catch(err => {
            notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
        })
        // eslint-disable-next-line
    },[])

    useEffect(()=>{
        if(!loading && userInfo.isGuide){
            updateNumberPrivacy(showContact).then(res => {
                notify('Privacy status updated.')
            }).catch(err => {
                notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
            })
        }
        // eslint-disable-next-line
    },[showContact])
    
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

  return (
    <div className={`${darkMode ? 'dark-mode' : ''} w-100`} style={{overflow:"hidden"}}>
        <BackButton size={24} text='Update Contact' />
        <div className='input-container input-container-uc update-username-container w-75'>
          <span className='label'>Current Contact</span>
          <input style={{width:'75%', backgroundColor:"transparent"}} value={userInfo?.contact} disabled />
        </div>
        <div className='px-20 w-100'>
            <PhoneInput
                country={'in'}
                value={contact}
                onChange={phone => setContact(phone)}
            />
        </div>

        {/* Get Otp button */}
        {!contactUpdated &&
            <>
                {!hideGetOtpButton ? (
                    <ButtonComponent title='Get OTP' onClick={fireOtpHandler} wrapperContainerStyle={{margin:"10px 20px"}} />
                ) : (
                    <p className='resend-otp text-center'>
                        {smsTimer > 0 ? `Resend OTP in ${formatTime(smsTimer)}` : <p>Don't receive the OTP ? <span onClick={fireOtpHandler} className='text-primary'>RESEND OTP</span></p>}
                    </p>
                )}
            </>
        }

        {/* <ButtonComponent onClick={() => updateHandler()} title='Get OTP' /> */}

        {/* otp boxes */}
        <OtpComponent state={state} setState={setState}/>

        <ButtonComponent onClick={contactUpdated ? () => true : showUpdate ? () => updateHandler() : () => verifyOtpHandler() } title={contactUpdated ? 'Updated' : showUpdate ? 'Update' : 'Verify'} wrapperContainerStyle={{margin:"10px 20px"}}/>
        {userInfo?.isGuide &&
            <div className='d-flex flex-end items-center px-20 my-20'>
                <p className='dark-mode-text'>Show My Number Publicly</p>
                <input checked={showContact} onChange={()=>setShowContact(!showContact)} type='checkbox' style={{marginLeft:'10px', width:20, height:20}}/> 
            </div>
        }
    </div>
  )
}

export default UpdateUsername