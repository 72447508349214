import React, { useContext } from 'react'
import { wizard2 } from '../../assets/images'
import { PiBeachBallThin, PiCity, PiGraduationCapThin, PiLightbulbThin, PiMapTrifoldLight, PiNavigationArrowLight, PiPencilSimpleLineLight, PiSealQuestionThin, PiSuitcaseLight, PiTranslate } from 'react-icons/pi'
import { IconInput, IconInputDropDown } from '../../components'
import Wrapper from './Wrapper'
import { StateContext } from '../../context/stateContext'
import { GUIDE_SERVICES_OPTIONS } from '../../utils/data'
import { IoGlobeOutline } from 'react-icons/io5'
import { PersonalInformationDesktop } from '../../assets/desktop-svgs'
import countryLists from 'react-select-country-list'


export const CustomDropDown = ({index, state, setState}) => {
    return (
      <select value={state} onChange={(e)=>setState(index, e.target.value)}>
        <option value='good'>Good</option>
        <option value='fluent'>Fluent</option>
      </select>
  )
}

const PersonalInformation = () => {
  let index = 0
  const {isTourist, userForm, setUserForm} = useContext(StateContext)
  const options = countryLists().getData()


  const countryChangeHandler = (code) => {
    if (code === 'none') {
        setUserForm(prevForm => ({
            ...prevForm,
            countryCode: '',
            country: ''
        }));
        return;
    }
    // TODO : profession , justify icon change if student 
    changeHandler('countryCode', code); 
    const countryName = options.find(country => country.value === code)?.label || '';
    setUserForm(prevForm => ({
        ...prevForm,
        countryCode: code,
        country: countryName
    }));
}


  const changeHandler = (key, text) => {
    setUserForm({...userForm, [key]:text})
  }

  const addLanguageHandler = () => {
    setUserForm({...userForm, languages: {...userForm?.languages,  [index+1] : {'language':'', 'fluency':''}}})
  }

  const languageInputHandler = (index, text) =>{
    setUserForm({...userForm, languages: {...userForm?.languages,  [index] : {...userForm?.languages?.[index], 'language':text}}})
  }

  const selectHandler = (index, value) => {
    setUserForm({...userForm, languages: {...userForm?.languages,  [index] : {...userForm?.languages?.[index], 'fluency':value}}})
  }

  return (
    <Wrapper image={wizard2} desktopImage={PersonalInformationDesktop} slideIndex={2} title='Personal Information' nextButtonText="Next" nextButtonUrl='/verification-wizard/personalize-account'>
      <IconInputDropDown value={userForm?.countryCode} onChange={(e)=>countryChangeHandler(e.target.value)} Icon={IoGlobeOutline} iconSize={30} selectData={options} style={{paddingLeft:5}}/>
      {/* {options.map(item => {
          return (
          <option key={item.label} value={item.value}><PiX size={10} color='red' className='dark-mode-text'/>{item.label}</option>
      )})} */}

      <IconInput value={userForm?.state} onChange={(e)=>changeHandler('state', e.target.value)} Icon={PiMapTrifoldLight } iconSize={30} placeholder='State' />
      <IconInput value={userForm?.city} onChange={(e)=>changeHandler('city', e.target.value)} Icon={PiCity } iconSize={30} placeholder='City' />
      {!isTourist &&
        <IconInput value={userForm?.cityOfService} onChange={(e)=>changeHandler('cityOfService', e.target.value)} Icon={PiNavigationArrowLight } iconSize={30} placeholder='Service Provided Locations' />
      }
      <IconInput value={userForm?.profession} onChange={(e)=>changeHandler('profession', e.target.value)} Icon={PiSuitcaseLight } iconSize={30} placeholder='Profession' />
      <div className='d-flex flex-end items-center'>
        <PiGraduationCapThin size={22} style={{margin:'0px 5px'}}/>
        <p className='dark-mode-text'>I Am a Student</p>
        <input checked={userForm?.isStudent} onChange={()=>changeHandler('isStudent', !userForm?.isStudent)} type='checkbox' style={{marginLeft:'10px', width:20, height:20}}/> 
      </div>
      <IconInput value={userForm?.justifyInFewWords} onChange={(e)=>changeHandler('justifyInFewWords', e.target.value)} Icon={
        userForm?.profession?.length > 0 ?
        PiSuitcaseLight:
        userForm?.isStudent ? 
        PiGraduationCapThin:
        PiPencilSimpleLineLight
      } 
      iconSize={30} placeholder='justify in few words (optional)' />
      {Object.values(userForm?.languages)?.map((item, i) => {
        index = Object.keys(userForm?.languages).length - 1
        return(
          <div className='cutomize-input-box' key={i}>
            <IconInput style={{width:'90%'}} value={item?.language} onChange={(e)=>languageInputHandler(i, e.target.value)} Icon={PiTranslate } iconSize={30} placeholder='Type a language' />
            <CustomDropDown index={i} state={item.fluency} setState={selectHandler} />
          </div>
        )
      })}
      <div className='d-flex flex-end items-center'>
          <p onClick={()=>addLanguageHandler()} className='text-whitesmoke bg-primary px-5 border-rounded mx-3 fs-14'>+</p>
          <p onClick={()=>addLanguageHandler()} className='fs-14 dark-mode-text'>Add more languages</p>
      </div>
      <IconInput value={userForm?.hobbiesAndInterest} onChange={(e)=>changeHandler('hobbiesAndInterest', e.target.value)} Icon={PiBeachBallThin } iconSize={30} placeholder='Hobbies & Interest' />
      <IconInput value={userForm?.skillOrTalent} onChange={(e)=>changeHandler('skillOrTalent', e.target.value)} Icon={PiLightbulbThin } iconSize={30} placeholder='Skill or Talent (optional)' />
      {!isTourist &&
        <IconInputDropDown value={userForm?.isGuide} onChange={(e)=>changeHandler('isGuide', e.target.value)} Icon={PiSealQuestionThin} iconSize={30} selectData={GUIDE_SERVICES_OPTIONS} style={{paddingLeft:15, fontSize:14}}/>
      }
      <br/>

    </Wrapper>
  )
}

export default PersonalInformation