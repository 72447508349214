import React, { useContext } from 'react'
import { StateContext } from '../../context/stateContext'

const CancellationRefundPolicy = () => {
    const {darkMode} = useContext(StateContext)
  return (
    <div className={`p-4 ${darkMode ? 'dark-mode': ''} `}>
       

    </div>
  )
}

export default CancellationRefundPolicy