import React, { useContext, useEffect, useState } from 'react'
import './homepage.styles.scss'
import { BsCalendar2Check, BsChatDots, BsNintendoSwitch } from 'react-icons/bs'
import { ChipComponent, DEventsCard, DPopularPlacesCard, DTestimonialsCard, SearchFilterInput, SideDrawer } from '../../components'
import { ImageCard } from '../../components'
import { useNavigate } from 'react-router-dom'
import { categoryBasedPlaces, fetchEvents, getUserDetails, placeBookmarkStatus, popularPlacesCategory, updateNotificationMetaInformation } from '../../utils/service'
import { StateContext } from '../../context/stateContext'
import { circles, city1, city2, city2Guide, city3, city4, city5, first, gradientCircle, guide2, guide3, guide4, homeBanner, logo, one, partyAllNight, two, user1, vector1, vector2, vector3, vector4, whyCity2Guide } from '../../assets/images'
import { PiMapPinFill, PiMoon, PiSun } from 'react-icons/pi'
import { FaUser, FaMapMarkerAlt, FaHeadset } from 'react-icons/fa'
import { TiCancelOutline } from "react-icons/ti";

const EVENT_CARD_STYLE = {
  maxHeight:200, minHeight:200, maxWidth:300, minWidth:300
}

const CITY_IMAGES = [
  {city:city1, name:"Hyderabad"}, 
  {city:city2, name:"Mumbai"},
  {city:city3, name:'Rajasthan'},
  {city:city4, name:"New Delhi"},
  {city:city5, name:"Kolkata"}
]


const DESC = [
  {ICON:FaUser  ,text:"100% private tour"},
  {ICON:FaMapMarkerAlt ,text:"Fully customizable itineraries"},
  {ICON:TiCancelOutline , size:26, text:"Flexible cancellation"},
  {ICON:FaHeadset ,text:"24/7 customer support"}
]

const HomePage = () => {
  
  const navigate = useNavigate()
  const {placesTab, setPlacesTab, darkMode, setDarkMode, userInfo, setUserForm, notificationIds, showNewMessageBadge, showNewBookingBadge, setShowNewMessageBadge, setShowNewBookingBadge, showNotificationBadge} = useContext(StateContext)
  const [showSideDrawer, setShowSideDrawer] = useState(false)
  const [shake, setShake] = useState(true)
  const [chips, setChips] = useState([])
  const [places, setPlaces] = useState([])
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(true)
  
  const badgeStatusToggle = (badgeName) => {
    if(badgeName.includes('booking')){
      setShowNewBookingBadge(false)
      const record = notificationIds.find(item => item.eventType === "booking")
      if(record){
        updateNotificationMetaInformation(record.id).catch(err=>{})
      }
      navigate('/Calendar')
    }
    if(badgeName.includes('message')){
      setShowNewMessageBadge(false)
      const record = notificationIds.find(item => item.eventType === "message")
      if(record){
        updateNotificationMetaInformation(record.id).catch(err=>{})
      }
      navigate('/ChatHome')
    }
  }

  const fetchCategoryBasedPlaces = (id) => {
    categoryBasedPlaces(id).then(res => {
      setPlaces(res.data)
      setLoading(false)
    }).catch(err => {
      console.log(err)
    })
  } 

  useEffect(()=>{
    if(placesTab?.id){
      fetchCategoryBasedPlaces(placesTab?.id)
    }
  },[placesTab])

  useEffect(()=>{
    // fetch events
    fetchEvents().then(res => {
      setEvents(res.data)
    }).catch(err => console.log(err))
    // fetch popular places category
    popularPlacesCategory().then(res => {
      setChips(res.data)
      if(placesTab.length <=0 ){
        setPlacesTab(res.data[0])
        fetchCategoryBasedPlaces(res.data[0].id)
      }
      
      // once response is received we can fetch category based places
    }).catch(err => console.log(err))
    // fetch the user details to store in global state why ? bcaz using few those values from response we have to calculate values for circular progress 
    getUserDetails().then(res =>{
      setUserForm(res.data)
    }).catch(()=>{})
    // shake animation related code
    const timer = setTimeout(()=>{
      setShake(false)
    }, 10000)

    return() => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line
  },[])


  const darkModeToggle = () => {
    setDarkMode(!darkMode)
    // 0 means light mode 
    // 1 means dark mode
    const currentTheme = localStorage.getItem('themeCg')
    if(currentTheme){
      if(currentTheme === '0'){
        setDarkMode(true)
        localStorage.setItem('themeCg', '1')
      }
      else{
        setDarkMode(false)
        localStorage.setItem('themeCg', '0')
      }
    }
    else{
      // by default on page we have dark mode so toggle will be for light
      setDarkMode(false)
      localStorage.setItem('themeCg', '0')
    }

  }

  return (
    <>
    
      <div className={`${darkMode ? 'dark-mode' : ''} homepage-container`}>
          {/* SideDrawer Import */}
          <SideDrawer isOpen={showSideDrawer} setIsOpen={setShowSideDrawer}/>        

          <div className='header-container'>
            <div>
              <h1 className='dark-mode-text'>Hi, {userInfo.fullName?.split(' ')[0]} <span className={`${shake ? 'rotate' : ''}`} style={{fontSize:30}}>👋</span></h1>
              <p className='dark-mode-text'>Explore the Uncommon</p>
            </div>
            <div className='home-quick-actions' style={{marginBottom:12}}>
                {/* Dark mode, Greetings , Icons, Drawer */}
                <div className='quick-actions-container login-page-container container'>
                  <div className='d-flex flex-end'>
                      {darkMode ?
                          <PiSun size={30} onClick={()=>darkModeToggle()} color={`${darkMode ? 'gray' : 'var(--black)'}`}/>
                          :
                          <PiMoon size={30} onClick={()=>darkModeToggle()} color={`${darkMode ? 'gray' : 'var(--black)'}`}/>
                      }
                  </div>
                </div>
                <div className='quick-actions-container login-page-container container'  onClick={()=>{badgeStatusToggle('booking')}}>
                  <BsCalendar2Check size={26} color='var(--black)' />
                    {showNewBookingBadge &&
                      <div className='badge'>
                        <span style={{position:"absolute", top:-8, left:3.5}}>*</span>
                      </div>
                    }
                </div>
                <div className='quick-actions-container login-page-container container'  onClick={()=>{badgeStatusToggle('message')}}>
                    <BsChatDots size={27} color='var(--black)' />
                    {showNewMessageBadge &&
                      <div className='badge'>
                      <span style={{position:"absolute", top:-8, left:3.5}}>*</span>
                      </div>
                    }
                </div>
                <div className='quick-actions-container login-page-container container' onClick={()=>setShowSideDrawer((prevState) => !prevState)}>
                  <BsNintendoSwitch size={26} color='var(--black)'/>
                    {showNotificationBadge &&
                      <div className='badge'>
                      <span style={{position:"absolute", top:-8, left:3.5}}>*</span>
                      </div>
                    }
                </div>
            </div>
          </div>
          
          {/* Search & Filter */}
          <SearchFilterInput onClick={()=>navigate('/Search')} onFocus={()=>navigate('/Search')}/>

          {/* Popular Places and View all : Text */}
          <div className='popular-places-text-container'>
            <h1>Popular Places</h1>
            {/* <p>View All</p> */}
          </div>

          {/* Places Category Tabs */}
          <div className='chip-container'>
            {chips.map((item, index) => (
                <ChipComponent 
                style={{marginRight: index === chips.length - 1 ? '0px' : '10px'}} 
                selected={item.name === placesTab?.name} 
                key={item.name + index} text={item.name} 
                onClick={()=>setPlacesTab(item)}/>
            ))}
          </div>

          {/* Places Cards */}
          <div className='places-card-container'>
              {loading ?
                <p>Loading indicator..</p>  
                :
                <>
                  {places.map(item => {
                    return(
                      <ImageCard 
                        key={item.id}
                        _id={item.id}
                        redirectText='/Details'
                        metaRedirectText={{"id":item.id}}
                        style={{minWidth:217, backgroundImage:`url(${item.image})` }} 
                        placeName={item.name} 
                        bookmarked={item.isSaved}
                        bookmarkFunction={placeBookmarkStatus}
                        visitorsCount={item.visitorsCount}
                        />
                    )
                  })}
                </>
              }
          </div>

          {/* Events & View all : Text */}
          <div className='events-text-container'>
            <h1>Events</h1>
            {/* <p>View All</p> */}
          </div>

          {/* Events Cards */}
          <div className='places-card-container'>
            {events.map(item => (
              <ImageCard 
                key={item.id} 
                _id={item.id}
                redirectText='/Details'
                metaRedirectText={{"id":item.id}}
                style={{...EVENT_CARD_STYLE, backgroundImage:`url(${item.image})`}} 
                placeName={item.name} visitorsCount={item.visitorsCount}
                bookmarked={item.isSaved}
                bookmarkFunction={placeBookmarkStatus}
              />
            ))}
          </div>
      </div>

      {/* Desktop */}
      <div className='desktop-homepage-container'>
            {/* bg image content */}
            <div className='desktop-bg-image' style={{backgroundImage:`url('${homeBanner}')`, height:'1000px', width:"100%"}}>
              <div className='position-relative p-20 h-100' >
                <div className='h-100'>
                  {/* logo */}
                  <div className='px-10 d-flex items-center border border-rounded width-max-content'>
                    <img src={logo} alt='' style={{height:50, width:50}}/>
                    <img src={city2Guide} alt='' style={{height:27, width:100}} />
                  </div>
                  {/* Title, desc, search bar */}
                  <div className='d-flex column content-center items-center h-100'>
                    <div style={{zIndex:10}} className='position-relative d-flex column items-center'>
                      <h1 className='text-whitesmoke' style={{fontSize:60}}>Find your  local guide </h1>
                      <p className='text-whitesmoke'>Truly get to know a place from people who know it best</p>
                      <SearchFilterInput showText={true} onClick={()=>navigate("/Search")} onFocus={()=>navigate('/Search')}/>
                      <div className='desktop-location-container position-absolute d-flex items-center'>
                        <PiMapPinFill color='var(--primary)' size={20} />
                        <p className='text-whitesmoke fs-14'>Hyderabad</p>
                      </div>
                    </div>
                    <div className='position-absolute w-100' style={{bottom:80}}>
                      <div className='desktop-info-container d-flex space-between items-center px-20'>
                        {DESC.map((item, index)=>{
                          return(
                            <div key={index} className='p-2 desktop-tab-container d-flex items-center'>
                              <item.ICON size={item.size || 20} color='white'/>
                              <p className='p-2 fw-500 text-whitesmoke'>{item.text}</p>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            {/* Popular city's */}
            <h1 className='my-20 fw-500 text-center' style={{margin:"50px 0px"}}>Popular City's</h1>
            <div className=' overflow-scroll'>

              <div className='my-10 px-10 d-flex space-evenly'>
                {CITY_IMAGES.map((item, index)=>{
                  return(
                    <div key={index} className='d-flex column items-center'>
                      <img className='city-image-container' src={item.city} alt={index} />
                      <p className='fw-600'>{item.name}</p>
                    </div>
                  )
                })}

              </div>

            </div>

            {/* Popular Places chips*/}
            <div className='overflow-scroll'>
              <h1 className='my-20 fw-500 text-center' style={{margin:"50px 0px"}}>Popular Place's</h1>
              <div className='d-flex space-evenly items-center'>
                <div className='d-flex items-center space-evenly'>
                  {chips.map((item, index) => (
                      <ChipComponent 
                      style={{marginRight: index === chips.length - 1 ? '0px' : '10px'}} 
                      selected={item.name === placesTab?.name} 
                      key={item.name + index} text={item.name} 
                      onClick={()=>setPlacesTab(item)}/>
                  ))}
                </div>
              </div>
            </div>

            {/* Popular places card  */}
            <div className='px-20 d-flex space-evenly overflow-scroll' style={{padding:'10px 40px'}}>
              <div className='d-flex space-between'>
                {places.map((item, index)=>{
                  return(
                    <DPopularPlacesCard 
                      key={index}
                      title={item.name}
                      image={item.image}
                      visitors={item.visitorsCount}
                      desc={item.description}
                      onClick={()=>navigate('/Details', { state: {"id":item.id} })}
                      buttonText='Expore Now'
                    />
                  )
                })}
              </div>
            </div>

            {/* View more button */}
            {/* <div className='text-center' style={{marginTop:40, marginBottom:80}}>
              <button className='view-more-button'>View More</button>
            </div> */}

            {/* Events */}
            <div>
              <h1 className='my-20 fw-500 text-center' style={{margin:"50px 0px 90px 0px"}}>Events</h1>
              <div className='d-flex space-evenly w-100'>
                <div className='event-text-container'>
                  <div className='px-20'>
                    <h1 className='fw-500 fs-40'>
                      Cheer up with<br/>
                      <span className='fw-600'>Best Indian Events</span>
                    </h1>
                    <p className='fs-14'>
                      Cheer Up with Best Indian Events" is an initiative designed to celebrate the rich cultural heritage and vibrant traditions of India through a series of engaging events. These events aim to foster community spirit, promote cultural awareness, and provide entertainment for participants of all ages.
                      <br/>
                      <br/>
                      Featuring a mix of traditional festivals, art exhibitions, music concerts, and food fairs, the events offer a platform for showcasing Indian art forms, culinary delights, and diverse customs. Attendees can experience the warmth of Indian hospitality while enjoying live performances, interactive workshops, and spirited competitions.
                    </p>
                  </div>
                </div>
                <div className='w-50 events-boxes-container' style={{marginBottom:300}}>
                  {/* column center first */}
                  <div className='d-flex column space-evenly'>
                    {/* row center now */}
                    <div className='d-flex row items-center position-relative'>
                      
                      <div className='d-flex column items-center'>

                        <div style={{marginLeft:-90, marginBottom:-60, height:70, width:70, borderTopLeftRadius:70, background:"#F5CA4E"}}>

                        </div>

                        <img style={{height:100, width:100, marginRight:-100, marginTop:-40, marginBottom:10}} src={partyAllNight} alt='party' />
                        
                        {/* first left box */}
                        <div className='bg-gray' style={{height:170, width:200, backgroundImage:`url('${two}')`, backgroundRepeat:"no-repeat"}}>
                            
                        </div>
                      </div>
                      {/* second side boxes */}
                      <div >
                        <div className='d-flex position-relative'>
                          <div className='bg-gray box-second1' style={{zIndex:100, marginLeft:30, backgroundImage:`url('${one}')`, backgroundRepeat:"no-repeat"}}>
                          </div>
                          <img style={{top:-80, left:160, zIndex:1}} src={gradientCircle} alt='gradient circle' className='position-absolute'/>
                        </div>
                        <div className='d-flex position-relative'>
                          <div className='bg-gray box-second2' style={{zIndex:100, marginLeft:30, marginTop:20 ,backgroundImage:`url('${first}')`, backgroundRepeat:"no-repeat"}}>
                          </div>
                          <img style={{top:20, left:40, zIndex:1}} src={circles} alt='circle pattern' className='image-circle position-absolute'/>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Upcoming Events */}

            <h1 className='my-20 fw-500 text-center' style={{margin:"0px 0px 90px 0px"}}>Upcoming Events</h1>
            <div className='d-flex space-evenly'>
              <div style={{width:"80%", marginBottom:80}} className='overflow-scroll'>
                <div className='d-flex items-center space-evenly' >
                    <div className='d-flex px-10 d-flex items-center space-evenly' >
                      {events.map((item, index)=>{
                        return(
                          <DEventsCard 
                            key={index}
                            image={item.image}
                            date={10}
                            month={'JUN'}
                            title={item.name}
                            desc={item.description}
                            isSaved={item.isSaved}
                          />
                        )
                      })}
                    </div>
                </div>
              </div>
            </div>

            {/* View more button */}
            {/* <div className='text-center' style={{marginTop:20}}>
              <button className='view-more-button'>View More</button>
            </div> */}

            {/* Why city2Guide */}
            <div className='why-city2guide-container'>
                <div className='text-container'>
                  <h1 className='fw-500 my-10'>Why city2Guide ?</h1>
                  <p>
                    City2Guide will change the way people travel, by making it easy to find  reliable, experienced
                    and inspiring local tour guides absolutely  everywhere.
                  </p>
                  <ul>
                    <li>
                      Travelers can discover new cities, towns and regions through many lenses:  history, photography,
                      food & wine, architecture, art, music, nature and  local culture.
                    </li>
                    <li>
                      Tourists wish to do unique things, experience the reality of a place and see its  culture & traditions
                      through the eyes of a local person. 
                    </li>
                    <li>
                      Our Guide will do everything they can to make your tour truly special,  unforgettable and unique to you.
                    </li>
                  </ul>
                </div>
                <div className='w-25'>
                  <img style={{height:600, width:500}} src={whyCity2Guide} alt='why city2 guide' />
                </div>

            </div>

            {/* Lorem Ipsum  */}
            <div>
              <h1 className='my-20 fw-500 text-center' style={{margin:"80px 0px 30px 0px"}}>About Us</h1>
              <div className='d-flex space-evenly w-100'>
                <div className='somethig-container'>
                      <b className='text-primary fw-500'>City2Guide</b> is a worldwide platform that provides connection between tourist & local guide, 
                      which help them focus on travel experience and explore food, culture and people of the city 
                      like a local. C2G will change the way people travel by making it easy to find reliable, 
                      experienced and inspiring local guides absolutely everywhere in the India. The platform 
                      offers a range of features to ensure personalized, authentic and memorable adventures for 
                      every traveler across all interests. <br/>
                      Travelers can discover new cities, towns and regions through many lenses: History, 
                      Photography, Food & Wine, Architecture, Ecotourism, Music, Meditation, Experiential Tours, 
                      and many more...
                </div>
              </div>
            </div>

            {/* Testimonials */}
            <div>
              <h1 className='text-center fw-500' style={{margin:"80px 0px"}}>Testimonials</h1>
              <div className='d-flex space-evenly overflow-scroll'>
                <DTestimonialsCard 
                  title='Cool Guide'
                  desc='My tour to Kanheri turned out amazing. Siddhi did an excellent job as our tour guide. She was constantly providing us interesting information with her vast knowledge about the Caves and Buddhism.
                        I will strongly recommend City2Guide.'
                  image={user1}
                  name='Faijan'
                  svg={vector1}
                  createdAt='10-Jan-2024'
                />
                <DTestimonialsCard 
                  title='Appreciation'
                  desc='I want to take a minute to appreciate my guide Mr. Anil, he is such a pure soul, hardworking and very down to earth person with charismatic personality. Wonderful places and food of Amchee Mumbai.'
                  image={guide2}
                  name='Aniket'
                  svg={vector2}
                  createdAt='10-Jan-2024'
                />
                <DTestimonialsCard 
                  title='Well Organized Trip'
                  desc='We have booked a local guide for Himachal from City2Guide.com - all arrangements starting from transport to hotel are well managed by Mr. Kumar, he was friendly, polite and well versed with all routes and places. Amazingly organized trip.'
                  image={guide4}
                  name='Joseph'
                  svg={vector3}
                  createdAt='10-Jan-2024'
                />
                <DTestimonialsCard 
                  title='Informative Tour'
                  desc="Informative, knowledgeable and professional tour guide l've ever met, worth every penny Mr.Sushil planned the tour especially for us and gave special attention to all our requirements. It's truly a unique experience to hire a local guide."
                  image={guide3}
                  name='Priyanka Jain'
                  svg={vector4}
                  createdAt='10-Jan-2024'
                />
              </div>
            </div>
      </div>
    </>
  )
}

export default HomePage