import React, { useContext } from 'react'
import { StateContext } from '../../context/stateContext'
import { url } from '../../config'
import { logo, makeInIndia, sb } from '../../assets/images'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
    const {darkMode} = useContext(StateContext)
    const navigate = useNavigate()
  return (
    <div className={`${darkMode ? 'dark-mode footer-h-100' : ''}`} style={{marginTop:-3}}>
        <div className='d-flex space-evenly column px-10 my-20'>
            <div style={{borderTop:"1px solid lightgray", paddingTop:10}}>
                <div className='d-flex items-center space-between position-relative'>
                    <p className='dark-mode-text fw-500 fs-20 my-5 px-10 '>City2Guide</p>
                    <img className='position-absolute' src={logo} alt='City2Guide logo' style={{
                        height: '230px',
                        width:' 230px',
                        right: '-7px',
                        top: '-20px',
                    }}/>
                    <img src={sb} alt='' style={{height:30, width:40, position:'absolute', top:150, right:130}}/>
                    <img src={makeInIndia} alt='' style={{height:50, width:50, position:'absolute', right:35, top:144}}/>
                </div>
                <div style={{marginTop:-10}} className='d-flex space-evenly column px-10'>
                    <p className='cursor-pointer width-max-content fs-14 my-2 dark-mode-gray-text' style={{marginTop:10}} onClick={()=>navigate('/About-Us')}>About Us</p>
                    {/* <p className='fs-14 my-2 dark-mode-gray-text' onClick={()=>window.open(url + '/media/files/PrivacyPolicy.pdf', '_blank')}>Privacy Policy</p>
                    <p className='fs-14 my-2 dark-mode-gray-text' onClick={()=>window.open(url + '/media/files/C2G_FAQ.pdf', '_blank')}>FAQ</p> */}
                    {/* <p className='cursor-pointer width-max-content fs-14 my-2 dark-mode-gray-text' onClick={()=>navigate('/All-Memberships')}>Memberships</p> */}
                    <p className='cursor-pointer width-max-content fs-14 my-2 dark-mode-gray-text' onClick={()=>navigate("/Best-Practices")}>Do's & Dont's</p>
                    <p className='cursor-pointer width-max-content fs-14 my-2 dark-mode-gray-text' onClick={()=>navigate('/FAQ')}>FAQ</p>
                    <p className='cursor-pointer width-max-content fs-14 my-2 dark-mode-gray-text' onClick={()=>navigate('/Privacy-Policy')}>Privacy and Policy</p>
                    {/* <p className='cursor-pointer width-max-content fs-14 my-2 dark-mode-gray-text' onClick={()=>navigate('/Cancellation-Refund-Policy')}>Cancellation & Refund Policy </p> */}
                    <p className='cursor-pointer width-max-content fs-14 my-2 dark-mode-gray-text' onClick={()=>window.open(url + '/media/files/TermsAndConditions.pdf', '_blank')}>Terms And Conditions</p>
                    <hr />
                </div>
            </div>
            <br/>
            <p className='fs-14 text-center dark-mode-text'>&copy; Copyright {new Date().getFullYear()}. All rights reserved.</p>
        </div>
    </div>
  )
}

export default Footer
