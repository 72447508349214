import React, { useContext } from 'react'
import './signuppage.styles.scss'
import { logo, Signup } from '../../assets/images'
import { useNavigate } from 'react-router-dom'
import { ButtonComponent, IconInput, IconInputDropDown } from '../../components'
import { BsCalendar, BsPerson } from 'react-icons/bs'
import { StateContext } from '../../context/stateContext'
import { useNotify } from '../../components/Toast'
import { PiGenderFemale, PiGenderMale, PiGenderNeuter } from 'react-icons/pi'
import { TbHeartHandshake } from 'react-icons/tb'
import { SignupDesktop } from '../../assets/desktop-svgs'

export const GENDER_DATA = [
    {id:1, value:'None', text:"Select Gender"},
    {id:2, value:'Male', text:"Male"},
    {id:3, value:'Female', text:"Female"},
    {id:3, value:'Other', text:"Other"},
    {id:4, value:'no-specify', text:"Prefer not to specify"},
]

const SignupPage = () => {
  const {darkMode, isTourist, setIsTourist, userForm, setUserForm} = useContext(StateContext)
  const navigate = useNavigate()
  const { notify } = useNotify()

  const redirectHandler = () => {
    if(userForm.fullName.length < 8){
      return notify('please write a valid name')
    }
    if(userForm.dob.length <= 7){
      return notify('please provide your dob')
    }
    if(userForm.gender.length === 0){
      return notify('please select gender')
    }
    if((new Date().getFullYear() - Number(userForm.dob.split('-')[0])) <= 17){
      return notify('Your age must be 18 or greater to signup.')
    }

    navigate('/Verification-wizard/contact-verification')
  }
  
  return (
    <div className={`${darkMode ? 'dark-mode' : ""}`} style={{minHeight:darkMode ? '106vh' : 'auto', overflow:"hidden"}}>
      <img src={logo} alt='city2guide logo' className='position-absolute hide-on-mobile' style={{height:100, width:100, top:10, left:10}}/>
      <div className='signup-container container'>
        <div className='responsive-image-container'>
          <img src={SignupDesktop} alt='india gate' />
        </div>
        <div className='responsive-form-container'>
          <div className='text-wrapper'>
              <h1>Create Account as {isTourist ? 'Tourist' : 'Guide'}</h1>
              <p style={{marginTop:-10, color:"var(--primary)"}} className='text-primary fs-14 cursor-pointer' onClick={()=>setIsTourist(!isTourist)}>switch account.</p>
              {isTourist ?
              <p className='hide-on-large-device'>Being your first adventure exploring captivating places.</p>
              :
              <p className='hide-on-large-device'>Let's help travellers to explore your city.</p>
              }
          </div>
          <div className='input-container-sp'>
              <IconInput value={userForm?.fullName} onChange={(e)=>setUserForm({...userForm, fullName:e.target.value})} Icon={BsPerson} iconSize={25} placeholder='Full Name' />
              <IconInput value={userForm?.dob} onChange={(e)=>setUserForm({...userForm, dob:e.target.value})} className='custom-input' type='date' hideLabel={true} Icon={BsCalendar} iconSize={25} placeholder='Date of Birth'/>
              <IconInputDropDown value={userForm?.gender} onChange={(e)=>setUserForm({...userForm, gender:e.target.value})} Icon={
                userForm?.gender === 'Male' ?
                PiGenderMale :
                userForm?.gender === 'Female' ?
                PiGenderFemale :
                userForm?.gender === 'Other' ?
                TbHeartHandshake :
                PiGenderNeuter
                
              } selectData={GENDER_DATA}/>
              <ButtonComponent  title='Create Account' wrapperContainerStyle={{marginTop:30}} onClick={()=>redirectHandler()}/>
              {/* <DividerComponent text='OR'/>
              <ButtonComponent title='Sign Up with Google' img={Google} buttonTextColor='black' wrapperContainerStyle={{backgroundImage:"linear-gradient(to right bottom, whitesmoke  41%, whitesmoke  100%)", position:"relative"}}/> */}
          </div>
          <p className='account-text my-10'>Already have an account ? <span className='cursor-pointer' onClick={()=>navigate('/Login')}>Login.</span></p>
          <img className='india-gate' src={Signup} alt='India gate with clouds and birds' />
        </div>
      </div>
    </div>
  )
}

export default SignupPage