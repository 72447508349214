import React, { useContext, useEffect, useState } from 'react';
import './fp.styles.scss';
import { SavedGuideMain, SavedPlacesMain } from '../../assets/images';
import { BackButton, ImageCard } from '../../components';
import { animated, useSpring } from '@react-spring/web';
import { checkIsNew, fetchSavedPlacesAndGuides, guideBookmarkStatus, placeBookmarkStatus, savedGuides } from '../../utils/service';
import { StateContext } from '../../context/stateContext';

const EVENT_CARD_STYLE = {
  maxHeight: 180, minHeight: 180, maxWidth: 120, minWidth: 120, margin: '5px', padding: 12, borderRadius: 10
};

const FPPage = () => {
  const {darkMode} = useContext(StateContext)
  const [isPlaces, setIsPlaces] = useState(true);
  const [placesData, setPlacesData] = useState([])
  const [guidesData, setGuidesData] = useState([])
  const [eventNotifier, setEventNotifier] = useState(false)

  const handleClick = (type) => {
    setIsPlaces(type === 'places');
  };

  const leftImageSpring = useSpring({
    transform: isPlaces ? 'translateX(0%)' : 'translateX(90%)',
    opacity: isPlaces ? 1 : 0,
    config: { tension: 280, friction: 60 }
  });

  const rightImageSpring = useSpring({
    transform: !isPlaces ? 'translateX(0%)' : 'translateX(-90%)',
    opacity: !isPlaces ? 1 : 0,
    config: { tension: 280, friction: 60 }
  });

  useEffect(()=>{
    fetchSavedPlacesAndGuides().then(res => {
      setPlacesData(res.data)
    }).catch(err => console.log(err))

    savedGuides().then(res => {
      setGuidesData(res.data)
    }).catch(err => alert(err))
  },[eventNotifier])

  return (
    <div className={`${darkMode ? 'dark-mode' : ""} fp-container`}>
      <BackButton size={30} style={{marginLeft:-10, fontSize:18}} text='Saved'/>

      <animated.img style={leftImageSpring} className={`banner-image ${isPlaces ? 'active' : 'mute'}`} src={SavedPlacesMain} alt='' />
      <animated.img style={rightImageSpring} className={`banner-image ${!isPlaces ? 'active' : 'mute'}`} src={SavedGuideMain} alt='' />

      <div className="button-container">
        <div className={`slider ${isPlaces ? 'left' : 'right'}`}></div>
        <button className="toggle-button" style={{ color: `${isPlaces ? 'white' : 'black'}` }} onClick={() => handleClick('places')}><span>Places</span></button>
        <button className="toggle-button" style={{ color: `${!isPlaces ? 'white' : 'black'}` }} onClick={() => handleClick('guide')}>Guide</button>
      </div>

      <div className='image-container-fp'>
        {isPlaces ?
        <>
        {placesData.length === 0 && <p className='dark-mode-text'>No places saved.</p>}
          {placesData.map((item, index) => {
            return (
              <div key={index}>
                <ImageCard
                  eventNotifier={eventNotifier}
                  setEventNotifier={setEventNotifier}
                  key={item.placeCard.id}
                  _id={item.placeCard.id}
                  redirectText='/details'
                  metaRedirectText={{"id":item.placeCard.id}}
                  placeName={item.placeCard.name} 
                  style={{ ...EVENT_CARD_STYLE, backgroundImage: `url(${item.placeCard.image})` }} 
                  bookmarked={item.placeCard.isSaved}
                  bookmarkFunction={placeBookmarkStatus}
                  alt={item.placeCard.name} />
              </div>
            );
          })}
        </>
        :
        <>
          {guidesData.length === 0 && <p className='dark-mode-text'>No guide saved.</p>}
          {guidesData.map((item, index) => {
            return (
              <div key={index}>
                <ImageCard
                  _id={item?.guideDetails?.id}
                  bookmarked={item?.guideDetails?.saved}
                  placeName={item?.guideDetails?.name}
                  isNew={checkIsNew(item.createdAt)}
                  redirectText='/Guide-Details'
                  metaRedirectText={{"id":item.guideDetails?.id, userId:item?.guideDetails?.id}}
                  style={{ ...EVENT_CARD_STYLE, backgroundImage: `url(${item?.guideDetails?.image})` }}
                  image={item?.guideDetails?.image}
                  alt={item?.guideDetails?.name}
                  bookmarkFunction={guideBookmarkStatus}
                  />
              </div>
            );
          })}
        </>
        }
        

      </div>
    </div>
  );
};

export default FPPage;
