import React, { useContext, useEffect, useRef, useState } from 'react'
import './notificationpage.styles.scss'
import { BackButton, Modal } from '../../components'
import { PiArrowRight, PiArrowsDownUp, PiBookmarkSimpleFill, PiCaretRightBold, PiLock, PiPhoneFill, PiSealQuestion, PiStar, PiWarningFill,  PiXCircleFill } from 'react-icons/pi'
import { noNotifications } from '../../assets/images'
import { fetchNotifications, GeneralSocket, getSingleReview, postReplyRateReview, updateTourAcceptanceStatus } from '../../utils/service'
import { useNotify } from '../../components/Toast'
import { formatDate, formatDistance, parseISO, subDays } from 'date-fns'
import { WiStars } from "react-icons/wi";
import { BsHandIndexThumb, BsMap } from "react-icons/bs";
import { IoShieldCheckmark } from 'react-icons/io5'
import { MdCelebration } from 'react-icons/md'
import constants from '../../constants'
import { StateContext } from '../../context/stateContext'
import { animated, useSpring } from '@react-spring/web'
import { url } from '../../config'
import { TbLocationSearch } from 'react-icons/tb'
import { LabelRating } from '../RateAndReview'

const NotificationsRenderer = ({day, notifications=[], onClick}) => {
  const [singleReviewData, setSingleReviewData] = useState({})
  const [reply, setReply] = useState('')
  const date = parseISO(day); 
  const formattedDate = formatDate(date, 'do MMMM (eeee) , yyyy ')
  const slideAnimation = useSpring({
		transform: singleReviewData?.review ? 'translateY(0%)' : 'translateY(-100%)',
		config: { tension: 100, friction: 15 }, // Spring-like configuration
	});
  const {notify} = useNotify()

  return(
    <>
      <h4 className='fw-500 my-10 dark-mode-gray-text'>{`${formattedDate}`}</h4>
        <div className='notification-page-modal-wrapper'>
          <Modal style={{minWidth:"350px", overflow:"hidden"}} isOpen={singleReviewData?.review} onClose={()=>setSingleReviewData({})}>
            <animated.div style={slideAnimation}>
                <h2 className='fs-20 fw-400' style={{left:10, marginTop:-15}}>{singleReviewData?.fromUser?.fullName} Tour Experience</h2>
                <LabelRating label={'Communication'} rating={singleReviewData?.communication}/>
                <LabelRating label={'Punctuality'} rating={singleReviewData?.punctuality}/>
                <LabelRating label={'Honesty & Politeness'} rating={singleReviewData?.honestyAndPoliteness}/>
                <LabelRating label={'Friendliness & Fun'} rating={singleReviewData?.friendlinessAndFun}/>
                <div className='d-flex column'>
                  <input style={{marginLeft:-4}} className='p-5 border-rounded' value={reply} onChange={e=>setReply(e.target.value)}/>
                  <div className='d-flex flex-end' style={{marginTop:8}}>
                    <p onClick={reply.length > 4 ? ()=>postReplyRateReview(singleReviewData.id, reply).then(res => {notify('Replied to the review !')}).catch(err => notify('error while submitting reply.')) : ()=>notify('please write a valid reply')} className='p-4 width-max-content text-whitesmoke fw-300' style={{borderRadius:10, marginTop:5, color:"black", border:"0.4px solid var(--primary)"}}>Write a comment</p>
                  </div>
                </div>
            </animated.div>
          </Modal>
        </div>
        {notifications.map(item => {
          const createdAt = parseISO(item.createdAt); 
          return(
            <React.Fragment key={item.id}>
              <div className={`my-20 d-flex items-center  border-rounded py-10 space-between ${item?.status === true ? 'completed' : item?.status === false ? 'cancelled' : ""} bg-lightgray`}>
                <div className={`d-flex items-center`} style={{flex:1}}>
                  {
                    item.text?.toLowerCase()?.includes('reported') ?
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:50, width:50, borderRadius:'50%'}}>
                      <PiWarningFill size={28} color='crimson'/>
                    </div>
                  :
                    item.text?.toLowerCase()?.includes('saved') ?
                      <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:50, width:50, borderRadius:'50%'}}>
                        <PiBookmarkSimpleFill size={28} color='orange'/>
                      </div>
                  :
                    item.text?.toLowerCase()?.includes('star') ?
                      <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:50, width:50, borderRadius:'50%'}}>
                        <PiStar size={28} color='orange'/>
                      </div>
                  :
                    item.text?.toLowerCase()?.includes('contact') ?
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:50, width:50, borderRadius:'50%'}}>
                      <PiPhoneFill size={28} color='green'/>
                    </div>
                  :
                    item.text?.toLowerCase()?.includes('nudge') ?
                    <div style={{position:"relative", display:"flex", justifyContent:"center", alignItems:"center", height:50, width:50, borderRadius:'50%'}}>
                      <WiStars style={{position:"absolute", top:-2, left:14}} size={20}/>
                      <BsHandIndexThumb size={22}/>
                    </div>
                  :
                    item.text?.toLowerCase()?.includes('requested') || item.text?.toLowerCase()?.includes('updated') ?
                      <div style={{position:"relative", display:"flex", justifyContent:"center", alignItems:"center", height:50, width:50, borderRadius:'50%'}}>
                        <BsMap style={{position:"absolute", top:-2, left:14}} size={20}/>
                        <BsHandIndexThumb size={22}/>
                      </div>
                  :
                    item.text?.toLowerCase()?.includes('otp generated') ?
                      <div style={{position:"relative", display:"flex", justifyContent:"center", alignItems:"center", height:50, width:50, borderRadius:'50%'}}>
                        <PiLock size={30} color='rgba(0, 0, 0, 0.8)'/>
                      </div>
                  :
                    item.text?.toLowerCase()?.includes('been verified') ?
                      <div style={{position:"relative", display:"flex", justifyContent:"center", alignItems:"center", height:50, width:50, borderRadius:'50%'}}>
                        <MdCelebration  color='purple' size={30} />
                      </div>
                  :
                    item.text?.toLowerCase()?.includes('review') ?
                      <div style={{position:"relative", display:"flex", justifyContent:"center", alignItems:"center", height:50, width:50, borderRadius:'50%'}}>
                        <PiSealQuestion  color='var(--primary)' size={30} />
                      </div>
                  :
                    item.text?.toLowerCase()?.includes('guide is available') ?
                      <div style={{position:"relative", display:"flex", justifyContent:"center", alignItems:"center", height:50, width:50, borderRadius:'50%'}}>
                        <TbLocationSearch   color='var(--primary)' size={30} />
                      </div>
                  :
                    item.text?.toLowerCase()?.includes('accepted') || item.text?.toLowerCase()?.includes('rejected') || item.text?.toLowerCase()?.includes('cancelled your tour') ?
                      <div style={{position:"relative", display:"flex", justifyContent:"center", alignItems:"center", height:50, width:50, borderRadius:'50%'}}>
                        {item.text?.toLowerCase()?.includes('accepted') ?
                          <IoShieldCheckmark color='green' style={{position:"absolute", left:14}} size={28}/>
                          :
                          <PiXCircleFill color='red' style={{position:"absolute", left:15}} size={30}/>

                        }
                      </div>
                  :
                    <div style={{backdropFilter:"blur(40px)"}}>
                      <img src={url + item?.profileImage} style={{backdropFilter:"blur(40px)", marginLeft:10, height:40, width:40, borderRadius:'50%'}} alt='' />
                    </div>
                      
                  }
                  <div className='column px-10' style={{flex:2}}>
                    <p style={{textTransform:"initial"}} className={`fw-600`}>
                      {item.text.split('&tourId=')[0]}
                    </p>
                    {item.text.includes('&tourId=') && <span className='text-lightgray fw-400 fs-12' style={{marginTop:-1}}>Booking ID : {item.text.split('&tourId=')[1]}</span>}
                    {item.rejectionStatus.length > 0 && <span className='text-lightgray fw-400 fs-12 d-flex items-center' style={{marginTop:-1}}>Reason&nbsp;&nbsp;<PiArrowRight className='px-3'/>{item.rejectionStatus}</span>}
                    <div className='d-flex items-center space-between'>
                      <p className='color-gray' style={{fontSize:12}}>{formatDistance(subDays(createdAt, 0), new Date(), { addSuffix: true })}</p>
                      {((item?.status === true) ||( item?.status === false)) &&
                        <p className='fs-10 border p-1 border-rounded'>{item?.status === true ? 'accepted' : item?.status === false ? 'cancelled' : ""}</p>
                      }
                      {console.log('item', item)}
                      {item.text.toLowerCase().includes("review") &&
                       <p onClick={()=>getSingleReview(item.reviewId).then((res)=>{setSingleReviewData(res.data)}).catch(err => notify('something went wrong while fetching the review.'))} className='fs-12 text-primary border-rounded-primary px-5'>view</p> 
                      }
                    </div>
                  </div>
                </div>
                <PiCaretRightBold size={22}/>
              </div>
              {(item.text?.toLowerCase()?.includes('requested') || item.text?.toLowerCase()?.includes('updated')) && (item.status === null || item.status === 'undefined') &&
                <div style={{marginTop:-20}} className='buttons-container dark-mode-adjust bg-lightgray p-5 d-flex flex-end items-center'>
                    <button className='cursor-pointer' onClick={()=>onClick(item._id, 'cancel')}>Cancel</button>
                    <button className='cursor-pointer' onClick={()=>onClick(item._id, 'accept')}>Accept</button>
                </div>
              }
            </React.Fragment>
          )
        })}
    </>
  )
}

const NotificationPage = () => {
  const {darkMode} = useContext(StateContext)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [isNewest, setIsNewest] = useState(true)
  const generalSocketRef = useRef()
  const { notify } = useNotify()

  const slideAnimation = useSpring({
    transform: !showModal ? 'translateY(20%)' : 'translateY(0%)',
    config: { tension: 100, friction: 10 },
  });


  const completelyReverseObject = (obj) => {
    // Step 1: Get the object keys and reverse their order
    const reversedKeys = Object.keys(obj).reverse();
  
    // Step 2: Create a new object and reverse the arrays for each key
    const reversedObj = {};
    reversedKeys.forEach(key => {
      reversedObj[key] = obj[key];  // Reverse the array for each date
    });
  
    return reversedObj;
  };
  
  const getNotifications = () => {
    fetchNotifications().then(res => {
      setData(res.data)
    }).catch(err => {
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    }).finally(()=>{
      setLoading(false)
    })
  }
  
  useEffect(()=>{
    getNotifications()
  //eslint-disable-next-line
  },[])


  useEffect(()=>{
    const newData = completelyReverseObject(data)
    setData(newData)
    // eslint-disable-next-line
  },[isNewest])

  const tourStatusHandler = (_id, status) => {
    updateTourAcceptanceStatus(_id, status).then(res => {
      notify('Status updated')
      const message = status === 'accept'  ? 'Wooho ! Guide has accepted your tour request.🎉': 'Opps ! Guide has rejected your tour request.'
      GeneralSocket(res.data.fromUserId, res.data.fromUserId, generalSocketRef, message, constants.bookingAndNotification)
      getNotifications()
    }).catch(err =>{
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    })
  }
  return (
    <div className={`${darkMode ? 'dark-mode' : ''}`}>
      <div className='px-20 my-20'>
        {/* Header : back icon, notifications text, sort by text, updown arrow */}
        <div className='d-flex space-between items-center'>
          <BackButton style={{marginLeft:"-20px", marginRight:10}} size={22} />
          <h2 className='fw-500 px-10 dark-mode-text'>Notifications</h2>
          <div className='d-flex items-center'>
            <p onClick={()=>setShowModal(!showModal)} className='color-gray px-5 dark-mode-gray-text' style={{fontSize:14}}>Sort By</p>
            <PiArrowsDownUp onClick={()=>setShowModal(!showModal)} className='dark-mode-gray-text' size={20}/>
            <animated.div style={slideAnimation} className={`${showModal ? '' : 'hide'} modal-container sort-modal-container`}>
              <p onClick={()=>{setIsNewest(!isNewest);setShowModal(false)}} className={`${isNewest ? 'active' : ""}`}>Newest to Oldest</p>
              <p onClick={()=>{setIsNewest(!isNewest);setShowModal(false)}} className={`${isNewest ? '' : "active"}`}>Oldest to Newest</p>
            </animated.div>
          </div>
        </div>
        {loading ?
        <div className='d-flex content-center items-center'>
            <p className='text-center'>Loading...</p>
        </div>
        :
        <>
          {Object.keys(data).length === 0 && !loading ?
            <div className='d-flex h-100 column content-center items-center'>
              <div className='d-flex content-center'>
                <img className='my-20' src={noNotifications} alt='No Notification'/>
              </div>
              <p className='dark-mode-gray-text'>No Notifications yet.</p>
            </div>
          :
          <>
            {Object.keys(data).map(key => (
              <NotificationsRenderer key={key} day={key} notifications={data[key]} onClick={tourStatusHandler}/>
            ))}
          </>
          }
        </>
        }




      </div>
    </div>
    
  )
}

export default NotificationPage