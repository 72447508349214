import React, { useContext, useEffect, useRef, useState } from 'react'
import { BackButton, ButtonComponent, OtpComponent } from '../../components'
import './forgotpasswordpage.styles.scss'
import { forgotPassword, success } from '../../assets/images'
import { PiArrowRight, PiEye, PiEyeSlash } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2';
import { StateContext } from '../../context/stateContext'
import { generateOtp, updatePasswordFunction, verifyOtp } from '../../utils/service'
import { useNotify } from '../../components/Toast'

const ForgotPasswordPage = () => {
  const {darkMode} = useContext(StateContext)
  const [page, setPage] = useState(1)
  const [contact, setContact] = useState('')
  const [focusedInput, setFocusedInput] = useState(0);
  const [state, setState] = useState({
    otp1:'',
    otp2:'',
    otp3:'',
    otp4:''
  })
  const smsApiFired = useRef()
  const [hideGetOtpButton, setHideGetOtpButton] = useState(false);
  const [smsTimer, setSmsTimer] = useState(0); // Timer for SMS
  const [otpVerified, setOtpVerified] = useState(false)
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const navigate = useNavigate()
  const { notify } = useNotify()

  const clear = () => {
    setState({
      otp1:'',
      otp2:'',
      otp3:'',
      otp4:''
    })
  }

  const handleDialPadClick = (value) => {
      const otpKeys = ['otp1', 'otp2', 'otp3', 'otp4'];

      if (focusedInput < otpKeys.length) {
          const key = otpKeys[focusedInput];
          const newValue = value.toString();
          setState((prevState) => ({
              ...prevState,
              [key]: newValue
          }));

          // Move focus to the next input
          setFocusedInput((prev) => Math.min(prev + 1, otpKeys.length - 1));
      }
  };


  const verifyOtpHandler = () => {
    if(otpVerified){
      return setPage(3)
    }
    if(!hideGetOtpButton){
        return notify('Please request for OTP first.')
    }
    if (!Object.values(state).every(val => val.length > 0)) {
        return notify('Please enter a valid OTP');
    }
    verifyOtp(contact, state)
        .then(res => {
            notify("OTP verified successfully!");
            setPage(3)
            setOtpVerified(true)
        })
        .catch(err => {
            notify(err.response && err.response.data.detail ? err.response.data.detail : err.message);
        });
  };

  const fireOtpHandler = () => {
    if (smsApiFired.current) {
        return;
    }

    if(contact.slice(0, 2) !== '91' && !(10 < contact.length && contact.length < 18)){
        return notify(`Please provide valid contact information`)
    }

    if(contact.slice(0, 2) === '91' && contact.length !== 12){
        return notify('Please enter valid contact number.')
    }

    smsApiFired.current = true;
    // true means we are firing for reset otp password from dlt as we have to switch the apis in backend for the message template.
    generateOtp(contact, false, true)
        .then(res => {
            notify("OTP sent successfully!");
            setHideGetOtpButton(true);
            setSmsTimer(119); // Start the SMS timer
            setPage(2)
        })
        .catch(err => {
            notify(err.response && err.response.data.detail ? err.response.data.detail : err.message);
        })
        .finally(() => {
            smsApiFired.current = false;
        });
  };

  const isValidPassword = (password) =>{
    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password)
  }

  const updatePasswordHandler = () => {
    if(password.length < 8 || repeatPassword.length < 8){
      return notify('Please enter valid password.')
    }
    if(password !== repeatPassword){
      return notify('Password do not match')
    }
    if(!isValidPassword(password)){
      return notify('Please make sure password meets all the requirements.')
    }
    updatePasswordFunction(contact, password).then(res => {
      notify('Password reset successfully.')
      navigate('/Login')
    }).catch(err => {
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    })
  }

  const formatTime = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const secs = seconds % 60;
      return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  useEffect(() => {
    if (smsTimer > 0) {
          const timerId = setTimeout(() => setSmsTimer(smsTimer - 1), 1000);
          return () => clearTimeout(timerId);
      }
  }, [smsTimer]);


  return (
    <div className={`${darkMode ? 'dark-mode': ''}`}>
      <div className='px-10 my-20 forgot-password-page-container'>
  

        {/* Image, reset password and helper text, flag input , button */}
        {page === 1 &&
          <>
            {/* Back button */}
            <BackButton size={24} style={{marginLeft:-20}}/>

            {/* Image */}
            <div className='d-flex space-evenly my-20'>
              <img src={forgotPassword} alt='forgot password' />
            </div>

            {/* Reset Password : text */}
            <p className='reset-password dark-mode-text'>Reset Password</p>

            {/* kindly enter registered : text */}
            <p className='help-text'>Kindly enter registered mobile number to reset your password.</p>

            {/* Flag input container */}
            <div className='w-100 tel-container'>
                <PhoneInput
                    country={'in'}
                    value={contact}
                    onChange={phone => setContact(phone)}
                />
            </div>

            {/* Button */}
            
            {!otpVerified &&
            <>
              {!hideGetOtpButton ? (
                  // <ButtonComponent title='Get OTP' onClick={fireOtpHandler} wrapperContainerStyle={{margin:"10px 20px"}} />
                  <ButtonComponent title='Get Otp' onClick={()=>fireOtpHandler()}/>

              ) : (
                  <p className='resend-otp text-center'>
                      {smsTimer > 0 ? `Resend OTP in ${formatTime(smsTimer)}` : <p>Don't receive the OTP ? <span onClick={fireOtpHandler} className='text-primary'>RESEND OTP</span></p>}
                  </p>
              )}
            </>
            }
          </>
        }

        {page === 2 &&
          <>
            {/* Back button */}
            <BackButton size={24} style={{marginLeft:-20}} onClick={()=>setPage(page - 1)}/>
              
            {/* Reset Password : text */}
            <p className='reset-password dark-mode-text'>Enter OTP</p>

            {/* kindly enter registered : text */}
            <p className='help-text'>If the number is registered with us, you will receive an OTP on *****{contact.slice(contact.length - 4)}.</p>

            {/* Otp Input */}
            <OtpComponent state={state} setState={setState} focusedInput={focusedInput} setFocusedInput={setFocusedInput}/>

            {/*  didn't receive otp ? RESEND OTP  : text*/}
            {!hideGetOtpButton ? (
                // <ButtonComponent title='Get OTP' onClick={fireOtpHandler} wrapperContainerStyle={{margin:"10px 20px"}} />
                <ButtonComponent title='Get Otp' onClick={()=>fireOtpHandler()}/>

            ) : (
                <p className='resend-otp text-center my-10'>
                    {smsTimer > 0 ? `Resend OTP in ${formatTime(smsTimer)}` : <p>Don't receive the OTP ? <span onClick={fireOtpHandler} className='text-primary'>RESEND OTP</span></p>}
                </p>
            )}
            <div className='d-flex space-evenly'>
              <div class="dial-pad">
                <button onClick={() => handleDialPadClick(1)} class="dial-button">1</button>
                <button onClick={() => handleDialPadClick(2)} class="dial-button">2</button>
                <button onClick={() => handleDialPadClick(3)} class="dial-button">3</button>
                <button onClick={() => handleDialPadClick(4)} class="dial-button">4</button>
                <button onClick={() => handleDialPadClick(5)} class="dial-button">5</button>
                <button onClick={() => handleDialPadClick(6)} class="dial-button">6</button>
                <button onClick={() => handleDialPadClick(7)} class="dial-button">7</button>
                <button onClick={() => handleDialPadClick(8)} class="dial-button">8</button>
                <button onClick={() => handleDialPadClick(9)} class="dial-button">9</button>
                <button onClick={()=>clear()} class="dial-button">C</button>
                <button onClick={() => handleDialPadClick(0)} class="dial-button">0</button>
                <button onClick={()=>verifyOtpHandler()} class="dial-button"><PiArrowRight /></button>
              </div>
            </div>
          </>
        }

        {page === 3 &&
          <>
            <div className='d-flex items-center'>
              {/* Back button */}
              <BackButton size={24} style={{marginLeft:-20}} onClick={()=>setPage(page - 1)}/>

            </div>

            {/* kindly enter registered : text */}
            <p className='help-text'>Your new password must be different from previously used password</p>
           
            <div className='w-100 d-flex '>
              <div className='w-90 px-10'>
                <div className='input-container'>
                  <span className='label'>New Password</span>
                  <input value={password} onChange={(e)=>setPassword(e.target.value)} type={showPassword ? 'text' : 'password'} style={{borderLeft:0}} />
                  {showPassword ?
                    <PiEye onClick={()=>setShowPassword(!showPassword)} color='var(--primary)'/>
                    :
                    <PiEyeSlash onClick={()=>setShowPassword(!showPassword)} color='var(--primary)'/>
                  }
                </div>
                
                <div className='input-container'>
                  <span className='label'>Cofirm Password</span>
                  <input value={repeatPassword} onChange={(e)=>setRepeatPassword(e.target.value)} type={showPassword ? 'text' : 'password'} style={{borderLeft:0}} />
                  {showPassword ?
                    <PiEye onClick={()=>setShowPassword(!showPassword)} color='var(--primary)'/>
                    :
                    <PiEyeSlash onClick={()=>setShowPassword(!showPassword)} color='var(--primary)'/>
                  }
                </div>
              </div>
            </div>
            <div className='px-10'>
              <p className='fw-400 fs-12 dark-mode-gray-text'>• At least 8 characters long</p>
              <p className='fw-400 fs-12 dark-mode-gray-text'>• Contains at least one uppercase letter, lowercase letter, one digit & special characher.</p>
            </div>
            <br/>
            <ButtonComponent title='Change Password' onClick={updatePasswordHandler}/>


              
          </>
        }

        {page === 4 &&

          <div className='my-20'>
            <div className='d-flex space-evenly'>
              <img src={success} alt='success' />
            </div>

            <p className='reset-password'>Success</p>

          {/* kindly enter registered : text */}
          <p className='help-text'>Your password has been<br/>updated successfully.</p>

          <ButtonComponent title='Okay' wrapperContainerStyle={{margin:"10px 20px"}} onClick={()=>navigate('/Login')}/>

              

          </div>
        }

      </div>
    </div>
      
  )
}

export default ForgotPasswordPage