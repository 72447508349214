import React, { useContext, useEffect, useRef, useState } from 'react'
import './editprofilepage.styles.scss'
import { BackButton, ButtonComponent, IconInput, IconInputDropDown, LineText} from '../../components'
import { useNotify } from '../../components/Toast'
import { getHourCharges, getItineraryImages, getUserDetails, updateChargesDetails, updateItineraryData, updateOtherInfo, updateUserDetails } from '../../utils/service'
import { StateContext } from '../../context/stateContext'
import { PiBeachBallThin, PiCalendar, PiCity, PiFlag, PiGenderFemale, PiGenderMale, PiGenderNeuter, PiGraduationCapThin, PiMapTrifoldLight, PiNotePencil, PiPen, PiPencil, PiPencilSimpleLineLight, PiSuitcase, PiTranslate, PiUser, PiX } from 'react-icons/pi'
import { HiOutlineLightBulb } from 'react-icons/hi'
import { CustomDropDown } from '../RegisterationWizardPage/PersonalInformation'
import { url } from '../../config'
import { useNavigate } from 'react-router-dom'
import CountryFlag from 'react-country-flag';
import countryLists from 'react-select-country-list'
import { GENDER_DATA } from '../SignupPage'
import { BsPerson } from 'react-icons/bs'
import { TbHeartHandshake } from 'react-icons/tb'
// import DatePicker from '../../components/DatePicker'


const CHOICE_DATA = [
    {id:1, text:"Yes", value:true},
    {id:2, text:"No", value:false}
]


const UploadImage = ({ src, onChange }) => {
    const inputRef = useRef();
    const [preview, setPreview] = useState(null);
    const [fileType, setFileType] = useState(null); // Track whether it's an image or video

    useEffect(() => {
        if(src && typeof src === 'string'){
            setFileType(src.endsWith('.mp4') ? 'video' : 'image'); // Simple check based on file extension
            setPreview(url + src)
        }
        if (src && typeof src === 'object' && src instanceof Blob) {
            setPreview(URL.createObjectURL(src));
            setFileType(src.type.startsWith('image') ? 'image' : 'video');
        } else if (src && typeof src === 'string') {
            setPreview(url + src);
            setFileType(src.endsWith('.mp4') ? 'video' : 'image'); // Simple check based on file extension
        }
    }, [src]);

    const handleFileChange = (file) => {
        if(!file){
            return
        }
        const validImageTypes = ['image/png', 'image/jpg', 'image/jpeg'];
        const validVideoTypes = ['video/mp4'];

        // Validate the file type
        if (validImageTypes.includes(file.type)) {
            setFileType('image');
        } else if (validVideoTypes.includes(file.type)) {
            setFileType('video');
        } else {
            alert('Invalid file type. Please upload an image (PNG, JPG, JPEG) or video (MP4).');
            return;
        }

        // Trigger the onChange callback with the file if valid
        onChange(file);
    };

    return (
        <div
            onClick={() => inputRef.current.click()}
            className="d-flex column content-center border-rounded items-center p-5 mx-5 bg-lightgray"
            style={{height:40, width:40}}
        >
            {src ? (
                fileType === 'image' ? (
                    <img
                        style={{ height: '100%', width: '100%', borderRadius: 4 }}
                        src={preview ? preview : ''}
                        alt=""
                    />
                ) : (
                    <video
                        onClick={() => inputRef.current.click()}
                        style={{ height: '100%', width: '100%' }}
                        controls
                    >
                        <source src={preview ? preview : ''} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                )
            ) : (
                <p className="px-10 fs-30 text-lightgray">+</p>
            )}
            <input
                type="file"
                ref={inputRef}
                hidden
                accept="image/png, image/jpg, image/jpeg, video/mp4"
                onChange={(e) => handleFileChange(e.target.files[0])}
            />
        </div>
    );
};


const EditProfilePage = () => {
    let index = 0
    let itineraryIndex = 0
    // let hourlyIndex = 0
    // let expertiseIndex = 0
    const options = countryLists().getData()
    const navigate = useNavigate()
    const [updateUserDetailsLoading, setUpdateUserDetailsLoading] = useState(false)
    const [updateItineraryLoading, setUpdateItineraryLoading] = useState(false)
    const [updatePricesLoading, setUpdatePricesLoading] = useState(false)
    const [otherInfoUpdateLoading, setOtherInfoUpdateLoading] = useState(false)
    const [userDetails, setUserDetails] = useState({})
    const [isFocused, setIsFocused] = useState(false)
    const inputRef = useRef()
    const {userInfo, setUserInfo, darkMode} = useContext(StateContext)
    const {notify} = useNotify()

    const [otherInfo, setOtherInfo] = useState({
        'nickName':'',
        'isInstantlyAvailable':true,
        'isVirtualTourAvailable':true
    })

    const [form, setForm] = useState({
        'name':'',
        'about':'',
        'dob':'',
        'description':'',
        'gender':'',
        'country':'',
        'cityOfService':"",
        'countryCode':'',
        'showCountryFlag':true,
        'state':'',
        'city':'',
        'zipCode':'',
        'profession':'',
        'isStudent':'',
        'justifyInFewWords':'',
        'hobbiesAndInterest':'',
        'languages':{
            0:{language:"", fluency:""}
        },
        'expertise':{
            0:{expertise:""}
        },
        "profileImage":"",
        "image1":null,
        "image2":null,
        "image3":null,
        "image4":null,
    })

    const [hoursBilling, setHoursBilling] = useState({
        0:{hour:'3h', price:'0'},
        1:{hour:'6h', price:'0'},
        2:{hour:'9h', price:'0'},
    })

    const [itineraryData, setItineraryData] = useState({
        0:{title:"", description:"", image:null}
    })


    const changeHandler = (key, text) => {
        setForm({...form, [key]:text})
    }

    const countryChangeHandler = (code) => {
        if (code === 'none') {
            setForm(prevForm => ({
                ...prevForm,
                countryCode: '',
                country: ''
            }));
            return;
        }
        changeHandler('countryCode', code); 
        const countryName = options.find(country => country.value === code)?.label || '';
        setForm(prevForm => ({
            ...prevForm,
            countryCode: code,
            country: countryName
        }));
    }
    
    const addLanguageHandler = () => {
        setForm({...form, languages: {...form?.languages,  [index+1] : {'language':'', 'fluency':''}}})
    }
    
    // const addExpertiseHandler = () => {
    //     setForm({...form, expertise: {...form?.expertise,  [expertiseIndex+1] : {'expertise':''}}})
    // }

    const expertiseInputHandler = (index, text, _id) =>{
        setForm({...form, expertise: {...form?.expertise,  [index] : {...form.expertise[index], 'expertise':text}}})
        if(_id){
            form.expertise[index]['id'] = _id
        }
    }
    
    const languageInputHandler = (index, text) =>{
        setForm({...form, languages: {...form?.languages,  [index] : {...form?.languages?.[index], 'language':text}}})
    }
  
    const addItineraryHandler = () => {
        setItineraryData({...itineraryData, [itineraryIndex+1]: {title:"", description:""}})
    }

    const removeItineraryHandler = () => {
        if(Object.keys(itineraryData).length <= 1){
            return notify('At least one itinerary is required.')
        }
        delete itineraryData[itineraryIndex]
        itineraryIndex = itineraryIndex - 1
        setItineraryData({...itineraryData})
    }

    const ItineraryInputHandler = (index, key, text, id) =>{
        setItineraryData({...itineraryData, [index] : {...itineraryData[index], [key]:text}})
        if(id){
            itineraryData[index]['_id'] = id
        }
    }
    
    // const addHourlyBillingsHandler = () => {
    //     setHoursBilling({...hoursBilling, [hourlyIndex+1]: {hour:"", price:""}})
    // }


    const HourlyBillingsInputHandler = (index, key, text, id) =>{
        setHoursBilling({...hoursBilling, [index] : {...hoursBilling[index], [key]:text}})
        if(id){
            hoursBilling[index]['_id'] = id
        }
    }

    const selectHandler = (index, value) => {
        setForm({...form, languages: {...form?.languages,  [index] : {...form?.languages?.[index], 'fluency':value}}})
    }

    const validateExpertise = () => {
        let allExpertiseEmpty = true
        for(let key in Object.keys(form.expertise)){
            if(form.expertise[key]['expertise'].length > 0){
                allExpertiseEmpty = false;
                break;
            }
        }
        return allExpertiseEmpty
    }

    const updateUserDetailsHandler = () => {
        setUpdateUserDetailsLoading(true)
        if(userInfo?.isGuide){
            if(validateExpertise()){
                return notify('please keep minimum one expertise.')
            }
        }
        updateUserDetails(form).then(res => {
            notify('Details updated')
            if(res.data.profileImage){
                const userDetails = JSON.parse(localStorage.getItem('auth-cg'))
                userDetails['profileImage'] = url + res.data.profileImage
                localStorage.setItem('auth-cg', JSON.stringify(userDetails))
                setUserInfo(userDetails)
            }
        }).catch(err =>{
            notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
        }).finally(()=>{
            setUpdateUserDetailsLoading(false)
        })
    }

    const updateItineraryDataHandler = () => {
        for(let key of Object.keys(itineraryData)){
            const item = itineraryData[key]
            if(item.title.length <= 3 || item.description.length <= 3 || !item.image || item.image === null){
                notify("please fill all the fields for new added itinerary")
                return
            }
        }
        setUpdateItineraryLoading(true)
        updateItineraryData(itineraryData).then(res => {
            notify('Itinerary data updated')
        }).catch(err => {
            notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
        }).finally(()=>{
            setUpdateItineraryLoading(false)
        })
    }

    const updateHourlyPrice = () => {
        setUpdatePricesLoading(true)
        updateChargesDetails(hoursBilling).then(res =>{
            notify('Hourly data updated')
        }).catch(err =>{
            notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
        }).finally(()=>{
            setUpdatePricesLoading(false)
        })
    }

    const otherInfoUpdateHandler = () => {
        setOtherInfoUpdateLoading(true)
        updateOtherInfo(otherInfo).then(res => {
            notify('Other information updated')
        }).catch(err => {
            notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
        }).finally(()=>{
            setOtherInfoUpdateLoading(false)
        })
    }

    useEffect(()=>{
        if(userInfo?.id){
            getUserDetails(userInfo.id).then(res =>{
                let data = res.data;
                setUserDetails(res.data)
                setOtherInfo({
                    'nickName':res.data.nickName,
                    'isInstantlyAvailable':res.data.isInstantlyAvailable,
                    'isVirtualTourAvailable':res.data.isVirtualTourAvailable
                })
                setForm({
                    'name':data.userDetails.fullName,
                    'about':data.about,
                    'dob':data.dob,
                    'gender':data.gender,
                    'description':data.description,
                    'country':data.country,
                    'cityOfService':data.cityOfService,
                    'countryCode':data.countryCode,
                    'showCountryFlag':data.showCountryFlag,
                    'state':data.state,
                    'city':data.city,
                    'zipCode':data.zipCode,
                    'profession':data.profession,
                    'isStudent':data.isStudent,
                    'justifyInFewWords':data.justifyInFewWords,
                    'hobbiesAndInterest':data.hobbiesAndInterest,
                    'languages': data?.languages && Object.keys(data.languages).length > 0 
                        ? { ...data.languages } 
                        : { 0: { language: "", fluency: "" } },

                    'expertise': data?.expertise && Object.keys(data.expertise).length > 0 
                        ? { ...data.expertise } 
                        : { 0: {'expertise': "" } },
                    'profileImage':data.profileImage,
                    'image1':data.image1,
                    'image2':data.image2,
                    'image3':data.image3,
                    'image4':data.image4,

                })
            }).catch(err => {
                notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
            })

            getItineraryImages().then(res => {
                const data = {}
                res.data?.forEach((item, index)=>{
                    data[index] = {_id : item.id, title:item.title, description:item.description, image:item.image}
                })
                if(Object.keys(data).length > 0){
                    setItineraryData(data)
                }
            }).catch(err => {
                notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
            })

            getHourCharges().then(res => {
                const data = {}
                res.data?.forEach((item, index)=>{
                    data[index] = {hour:item.hour, price:String(item.price), _id:item.id}
                })
                if(Object.keys(data).length > 0){
                    setHoursBilling(data)
                }
            }).catch(err => {
                notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
            })

        }
    //eslint-disable-next-line
    },[userInfo])


    
    return (
        <div className={`${darkMode ? 'dark-mode' : ''}`}>
            <div className='px-20'>
                <BackButton style={{marginLeft:-20}}/>
                <div className='px-10'>
                    {/* Personal information : text */}
                    <h2 className='fw-500 text-center dark-mode-text'>Manage Profile</h2>

                    {/* Profile image , flag, and plus icon */}
                    <div className='upload-image text-center'>
                        {form.showCountryFlag &&
                            <CountryFlag className='country-flag' countryCode={form.countryCode} svg style={{ width: '20px', height: '20px', borderRadius:"0% !important" }} />
                        }
                        <img style={{background:"white"}} className='profile-image w-25 h-25' onClick={()=>document.getElementById('input-id').click()} src={typeof form?.profileImage === 'object' ? URL.createObjectURL(form.profileImage) : form?.profileImage?.includes('/media/') ? form?.profileImage : ''} alt={userDetails?.username}/>
                        <input onChange={(event)=>setForm({...form, profileImage:event.target.files[0]})} type='file' hidden id='input-id' />
                        <div className='cursor-pointer' onClick={()=>document.getElementById('input-id').click()}>
                            <p>+</p>
                        </div>
                    </div>

                    {/* other 4 images */}
                    <div className='d-flex content-center'>
                        <UploadImage src={form.image1} onChange={(file)=>setForm({...form, image1:file})}/>
                        <UploadImage src={form.image2} onChange={(file)=>setForm({...form, image2:file})}/>
                        <UploadImage src={form.image3} onChange={(file)=>setForm({...form, image3:file})}/>
                        <UploadImage src={form.image4} onChange={(file)=>setForm({...form, image4:file})}/>
                    </div>
                </div>
                {/* Personal Information seperator */}
                <LineText text='Personal Information' />
                {/* name, about, dob, gender, country,  */}
                <div>
                    <IconInput Icon={BsPerson} disabled={userDetails?.isVerified} value={form?.name} onChange={(e)=>changeHandler('name', e.target.value)} placeholder='Name'/>
                    <IconInput Icon={PiNotePencil} value={form?.about} onChange={(e)=>changeHandler('about', e.target.value)} placeholder='About'/>
                        
                    {/* Description */}
                    <div className='InputBox'>
                        <label onClick={()=>{setIsFocused(true); inputRef?.current?.focus()}} className={`label ${isFocused || form?.description?.length > 0 ? 'active' : ""}`}>{!isFocused && form?.description?.length > 0 ? 'Description' : 'Description'}</label>
                        <textarea style={{resize:"none"}} rows='4' ref={inputRef} value={form?.description} onChange={(e)=>changeHandler('description', e.target.value)} onFocus={()=>setIsFocused(true)} onBlur={()=>setIsFocused(false)}/>
                    </div>
                    {/* <DatePicker /> */}
                        
                    <div className='half-input-container'>
                        <IconInput Icon={PiCalendar} disabled={userDetails?.isVerified} value={form?.dob} hideLabel={true} type='date' onChange={(e)=>changeHandler('dob', e.target.value)} placeholder='Date Of Birth'/>

                        <select value={form?.countryCode} onChange={(e)=>countryChangeHandler(e.target.value)} className='w-50 ep-customize-select'>
                            
                            <option value='none'><PiX className='dark-mode-text'/>Select Country</option>
                            {options.map(item => {
                                return (
                                <option key={item.label} value={item.value}><PiX size={10} color='red' className='dark-mode-text'/>{item.label}</option>
                            )})}
                        </select>
                        
                        <IconInput Icon={PiMapTrifoldLight } disabled={userDetails?.isVerified} value={form?.state} onChange={(e)=>changeHandler('state', e.target.value)} placeholder='State'/>
                        <IconInput Icon={PiCity } disabled={userDetails?.isVerified} value={form?.city} onChange={(e)=>changeHandler('city', e.target.value)} placeholder='City'/>
                        {/* <select value={form?.gender} onChange={(e)=>changeHandler('gender', e.target.value)} >
                            {GENDER_DATA.slice(1, 6).map(item => {
                                return (
                                <option key={item.text} value={item.value}>{item.text}</option>
                            )})}
                        </select> */}
                        <IconInputDropDown value={form.gender}  wrapperContainerStyle={{width:'45%', height:'40px', margin:'10px 0px'}} onChange={(e)=>changeHandler('gender', e.target.value)} Icon={
                            form?.gender === 'Male' ?
                            PiGenderMale :
                            form?.gender === 'Female' ?
                            PiGenderFemale :
                            form?.gender === 'Other' ?
                            TbHeartHandshake :
                            PiGenderNeuter
                            
                        } selectData={GENDER_DATA}/>
                        {userInfo?.isGuide &&
                            <IconInput value={form?.cityOfService} onChange={(e)=>changeHandler('cityOfService', e.target.value)} placeholder='City Of Service'/>
                        }

                        {/* <div className='w-50'>
                            <IconInputDropDown value={form?.gender} onChange={(e)=>changeHandler('gender', e.target.value)} Icon={BsGenderAmbiguous} selectData={GENDER_DATA.slice(1, 6)}/>
                        </div> */}
                        {/* <IconInput value={form?.zipCode} type='number' onChange={(e)=>changeHandler('zipCode', e.target.value)} placeholder='Zip Code'/> */}
                    </div>
                    <div className='d-flex flex-end items-center'>
                        <PiFlag size={22} style={{margin:'0px 5px'}}/>
                        <p className='dark-mode-gray-text'>Show Flag Next to Profile</p>
                        <input checked={form?.showCountryFlag} onChange={form?.countryCode === 'null' || form?.countryCode === null ? ()=>notify('please select the country first.') : ()=>changeHandler('showCountryFlag', !form?.showCountryFlag)} type='checkbox' style={{marginLeft:'10px', width:20, height:20}}/> 
                    </div>
                    <LineText text='Profession' />
                    <IconInput value={form?.profession} onChange={(e)=>changeHandler('profession', e.target.value)} Icon={PiSuitcase} placeholder='Profession'/>
                
                    <div className='d-flex flex-end items-center'>
                        <PiGraduationCapThin size={22} style={{margin:'0px 5px'}}/>
                        <p className='dark-mode-gray-text'>I Am a Student</p>
                        <input checked={form?.isStudent} onChange={()=>changeHandler('isStudent', !form?.isStudent)} type='checkbox' style={{marginLeft:'10px', width:20, height:20}}/> 
                    </div>
                    <IconInput value={form?.justifyInFewWords} onChange={(e)=>changeHandler('justifyInFewWords', e.target.value)} Icon={PiPencilSimpleLineLight} iconSize={30} placeholder='Justify (optional)' />

                    {/* Languages, hobbies and interest */}
                    <LineText text={userInfo?.isGuide ? 'Languages, Hobbies & Expertise' : 'Languages & Hobbies' } />
                    {Object.values(form?.languages)?.map((item, i) => {
                        index = Object.keys(form?.languages).length - 1
                        return(
                        <div className='cutomize-input-box' key={i}>
                            <IconInput style={{width:'90%'}} value={item?.language} onChange={(e)=>languageInputHandler(i, e.target.value)} Icon={PiTranslate} iconSize={30} placeholder='Type a lanugage' />
                            <CustomDropDown index={i} state={item.fluency} setState={selectHandler} />
                        </div>
                        )
                    })}
                    <div className='d-flex flex-end items-center'>
                        <p onClick={()=>addLanguageHandler()} className='cursor-pointer text-whitesmoke bg-primary px-5 border-rounded mx-3 fs-14'>+</p>
                        <p onClick={()=>addLanguageHandler()} className='cursor-pointer fs-14 dark-mode-gray-text'>Add more</p>
                    </div>
                    <IconInput value={form?.hobbiesAndInterest} onChange={(e)=>changeHandler('hobbiesAndInterest', e.target.value)} Icon={PiBeachBallThin} iconSize={30} placeholder='Hobbies & Interest' />

                    {/* Area of Expertise */}
                    {userInfo?.isGuide &&
                    <>
                        {/* <p className='my-5 dark-mode-gray-text'>Area of Expertise</p> */}
                        {Object.values(form?.expertise)?.map((item, i) => {
                            // expertiseIndex = Object.keys(form?.expertise).length - 1
                            return(
                                <IconInput key={i} style={{width:'100% !important'}} value={item?.expertise} onChange={(e)=>expertiseInputHandler(i, e.target.value, item?.id)} Icon={HiOutlineLightBulb} iconSize={30} placeholder='Type your area of expertise' />
                            )
                        })}
                    </>
                    }
                    {/* <div className='d-flex flex-end items-center'>
                        <p onClick={()=>addExpertiseHandler()} className='cursor-pointer text-whitesmoke bg-primary px-5 border-rounded mx-3 fs-14'>+</p>
                        <p onClick={()=>addExpertiseHandler()} className='cursor-pointer fs-14 dark-mode-gray-text'>Add more</p>
                    </div> */}
                    
                    <br />
                    <ButtonComponent loading={updateUserDetailsLoading} onClick={()=>updateUserDetailsHandler()} title={`Update Details`}/>
                    <ButtonComponent title='Cancel' buttonTextColor='black' onClick={()=>navigate(-1)} wrapperContainerStyle={{margin:"10px 0px", backgroundImage:"linear-gradient(to right, whitesmoke, whitesmoke)"}}/>
                    
                    {/* Itinerary for guide */}

                    {userInfo?.isGuide &&
                        <>
                            <LineText text='Itinerary' />
                            {Object.keys(itineraryData).map((item, index)=>{
                                itineraryIndex = Object.keys(itineraryData).length - 1
                                return(
                                    <div style={{marginBottom:10}} key={item}>
                                        <div className='d-flex items-center space-between'>
                                            <p className='dark-mode-gray-text'>Day {index + 1}</p>
                                            {index !== 0 &&
                                                <p onClick={()=>removeItineraryHandler()} className='cursor-pointer dark-mode-gray-text border-rounded-primary p-2 fs-12'>— remove</p>
                                            }
                                        </div>
                                        <IconInput value={itineraryData[index]?.title} onChange={(e)=>ItineraryInputHandler(index, 'title', e.target.value, itineraryData[index]?.id)}  Icon={PiPen} placeholder='Title e.g. Taj Hotel'/>
                                        <IconInput value={itineraryData[index]?.description} onChange={(e)=>ItineraryInputHandler(index, 'description', e.target.value, itineraryData[index]?.id)}  Icon={PiPencil} placeholder='Description about the place'/>
                                        <div style={{height:60, width:60, marginLeft:-10}}>
                                            <UploadImage src={itineraryData[index]?.image} onChange={(file)=>setItineraryData({...itineraryData, [index]:{...itineraryData[index], image:file}})}/>
                                        </div>
                                    </div>
                                )
                            })}
                            <p className='text-primary cursor-pointer fs-14 my-5' onClick={itineraryIndex < 3 ? ()=>addItineraryHandler() : ()=>alert('limit reached.')}>Add More</p>
                            <ButtonComponent loading={updateItineraryLoading} onClick={()=>updateItineraryDataHandler()} title='Update Itinerary'/>
                        </>
                    }
                    {/* Hourly charges for guide */}
                    {userInfo?.isGuide &&
                        <>
                            <LineText text='Hour Charges' />
                            {Object.keys(hoursBilling).map((item, index)=>{
                                // hourlyIndex = Object.keys(hoursBilling).length - 1
                                return(
                                    <div style={{marginBottom:10}} key={item}>
                                        <p className='dark-mode-gray-text '>Billing {index + 1}</p>
                                        <div className='d-flex space-between items-center'>
                                            <IconInput disabled={index < 3} value={hoursBilling[index]?.hour} onChange={(e)=>HourlyBillingsInputHandler(index, 'hour', e.target.value, hoursBilling[index]?.id)}  Icon={PiPen} placeholder='Hour'/>
                                            <IconInput value={hoursBilling[index]?.price} onChange={(e)=>HourlyBillingsInputHandler(index, 'price', e.target.value, hoursBilling[index]?.id)}  Icon={PiPencil} placeholder='Price in INR'/>
                                        </div>
                                    </div>
                                )
                            })}
                            {/* <p className='text-primary cursor-pointer fs-14 my-5' onClick={hourlyIndex < 4 ? ()=>addHourlyBillingsHandler() : ()=>alert('limit reached.')}>Add More</p> */}
                            <br />
                            <ButtonComponent loading={updatePricesLoading} onClick={()=>updateHourlyPrice()} title='Update Prices'/>
                        </>
                    }
                    {/* Other infor for guide */}
                    {userInfo?.isGuide &&
                        <>
                            <LineText text='Other Information' />
                            <IconInput Icon={PiUser} value={otherInfo?.nickName} onChange={e=>setOtherInfo({...otherInfo, 'nickName':e.target.value})} placeholder='What you would like to be called ?' />
                            <p className='my-5 fs-14 text-lightgray dark-mode-gray-text'>Will you be available instantly ?</p>
                            <IconInputDropDown value={otherInfo?.isInstantlyAvailable} onChange={(e)=>setOtherInfo({...otherInfo, 'isInstantlyAvailable':e.target.value})} selectData={CHOICE_DATA}/>
                            <p className='my-5 fs-14 text-lightgray dark-mode-gray-text'>Will you be available for Virtual tour  ?</p>
                            <IconInputDropDown value={otherInfo?.isVirtualTourAvailable} onChange={(e)=>setOtherInfo({...otherInfo, 'isVirtualTourAvailable':e.target.value})} selectData={CHOICE_DATA}/>

                            <br/>
                            <ButtonComponent loading={otherInfoUpdateLoading}  onClick={()=>otherInfoUpdateHandler()} title='Update Information'/>
                        </>
                    }
                    <br/>
                    <br/>
                </div>
                    
            </div>
        </div>
            
    )
}

export default EditProfilePage