import React, { useContext, useEffect, useRef, useState } from 'react';
import './all-component.styles.scss';
import { wizard1 } from '../../assets/images';
import { ButtonComponent, IconInput, OtpComponent } from '../../components';
import { CiMail } from "react-icons/ci";
import Wrapper from './Wrapper';
import PhoneInput from 'react-phone-input-2';
import { StateContext } from '../../context/stateContext';
import { ContactVerificationDesktop } from '../../assets/desktop-svgs';
import { useNotify } from '../../components/Toast';
import { generateOtp, verifyOtp } from '../../utils/service';
import { PiLightbulb } from 'react-icons/pi';

const ContactVerification = () => {
    const { userForm, setUserForm } = useContext(StateContext);
    const { notify } = useNotify();
    const smsApiFired = useRef();
    // const emailApiFired = useRef();

    const [hideGetOtpButton, setHideGetOtpButton] = useState(false);
    const [disableVerifyOtp, setDisableVerifyOtp] = useState(false);
    // const [hideGetEmailOtpButton, setHideGetEmailOtpButton] = useState(false);
    // const [disableVerifyEmailOtp, setDisableVerifyEmailOtp] = useState(false);
    
    const [smsTimer, setSmsTimer] = useState(0); // Timer for SMS
    const [emailTimer, setEmailTimer] = useState(0); // Timer for Email OTP
    const [otpState, setOtpState] = useState({ otp1: '', otp2: '', otp3: '', otp4: '' });

    const startTimer = (setTimer) => {
        setTimer(119); // Start timer at 1:59 (119 seconds)
    };

    const fireOtpHandler = () => {
        if (smsApiFired.current) {
            return;
        }
        if(userForm?.contact?.slice(0, 2) !== '91' && !(10 < userForm?.contact?.length && userForm?.contact?.length < 18)){
            return notify(`Please provide valid contact information`)
        }
          if(userForm?.contact?.slice(0, 2) === '91' && userForm?.contact.length !== 12){
            return notify('Please enter valid contact number.')
        }
        if (userForm?.contact.length < 12) {
            return notify('Please enter a valid number');
        }
        smsApiFired.current = true;
        generateOtp(userForm?.contact)
            .then(res => {
                notify("OTP sent successfully!");
                setHideGetOtpButton(true);
                startTimer(setSmsTimer); // Start the SMS timer
            })
            .catch(err => {
                notify(err.response && err.response.data.detail ? err.response.data.detail : err.message);
            })
            .finally(() => {
                smsApiFired.current = false;
            });
    };

    const verifyOtpHandler = () => {
        if (!Object.values(otpState).every(val => val.length > 0)) {
            return notify('Please enter a valid OTP');
        }
        verifyOtp(userForm?.contact, otpState)
            .then(res => {
                notify("OTP verified successfully!");
                setDisableVerifyOtp(true);
                setUserForm({...userForm, 'contactVerified':true})
            })
            .catch(err => {
                notify(err.response && err.response.data.detail ? err.response.data.detail : err.message);
            });
    };

    // Email Related
    // const sendEmailOtpHandler = () => {
    //     if (emailApiFired.current) {
    //         return;
    //     }
    //     const emailRegex = /^[a-zA-Z0-9._]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    //     if (!emailRegex.test(userForm?.email)) {
    //         return notify('Please enter a valid email.');
    //     }
    //     emailApiFired.current = true;
    //     sendOnBoardingEmail(userForm?.email)
    //         .then(res => {
    //             notify("OTP has been sent on your email successfully");
    //             // setHideGetEmailOtpButton(true);
    //             startTimer(setEmailTimer); // Start the Email timer
    //         })
    //         .catch(err => {
    //             notify(err.response && err.response.data.detail ? err.response.data.detail : err.message);
    //         })
    //         .finally(() => {
    //             emailApiFired.current = false;
    //         });
    // };

    // const verifyEmailHandler = () => {
    //     verifyEmail(userForm?.email, emailState)
    //         .then(res => {
    //             notify("OTP verified successfully!");
    //             // setDisableVerifyEmailOtp(true);
    //             setUserForm({...userForm, 'contactVerified':true})
    //         })
    //         .catch(err => {
    //             notify(err.response && err.response.data.detail ? err.response.data.detail : err.message);
    //         });
    // };

    // const [emailState, setEmailState] = useState({ otp1: '', otp2: '', otp3: '', otp4: '' });

    useEffect(() => {
        if (smsTimer > 0) {
            const timerId = setTimeout(() => setSmsTimer(smsTimer - 1), 1000);
            return () => clearTimeout(timerId);
        }
    }, [smsTimer]);

    useEffect(() => {
        if (emailTimer > 0) {
            const timerId = setTimeout(() => setEmailTimer(emailTimer - 1), 1000);
            return () => clearTimeout(timerId);
        }
    }, [emailTimer]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    return (
        <Wrapper image={wizard1} desktopImage={ContactVerificationDesktop} slideIndex={1} title='Contact Verification' nextButtonText="Next" nextButtonUrl='/verification-wizard/personal-information'>
            <div className='w-100'>
                <PhoneInput
                    containerClass='react-telephone-custom-class'
                    country={'in'}
                    value={userForm?.contact}
                    onChange={phone => setUserForm({ ...userForm, contact: phone })}
                />
            </div>
            {!disableVerifyOtp &&
                <>
                    {!hideGetOtpButton ? (
                        <ButtonComponent title='Get OTP' onClick={fireOtpHandler} />
                    ) : (
                        <p className='resend-otp'>
                            {smsTimer > 0 ? `Resend OTP in ${formatTime(smsTimer)}` : <p>Don't receive the OTP ? <span onClick={fireOtpHandler}>RESEND OTP</span></p>}
                        </p>
                    )}
                </>
            }
            <OtpComponent state={otpState} setState={setOtpState} />
            {hideGetOtpButton && (
                <ButtonComponent wrapperContainerStyle={{ marginTop: 10 }} title={disableVerifyOtp ? 'Mobile Number Verified' : 'Verify Otp'} onClick={disableVerifyOtp ? () => true : verifyOtpHandler} />
            )}
            <hr />
            <IconInput value={userForm?.email} onChange={(e) => setUserForm({ ...userForm, email: e.target.value })} type='email' Icon={CiMail} iconSize={29} placeholder='Email' />
            <div className='bg-lightgray border-rounded p-20 d-flex space-between items-center'>
                <PiLightbulb size={50} className='me-2'/>
                <p className='fs-14 fw-500'> Kindly enter your valid email address <br/>to update or reset password in future.<br/></p>
            </div>
            <br/>
           
            {/* {!disableVerifyEmailOtp &&
                <>
                {!hideGetEmailOtpButton ? (
                    <ButtonComponent wrapperContainerStyle={{ marginTop: '20px' }} title='Get OTP' onClick={sendEmailOtpHandler} />
                ) : (
                    <p className='resend-otp' style={{ marginBottom: 20 }}>
                        {emailTimer > 0 ? `Resend Email OTP in ${formatTime(emailTimer)}` : <p>Don't receive the OTP ? <span onClick={sendEmailOtpHandler}>RESEND EMAIL</span></p>}
                    </p>
                )}
                </>
            }
            <OtpComponent state={emailState} setState={setEmailState} />
            {hideGetEmailOtpButton && (
                <ButtonComponent wrapperContainerStyle={{ marginTop: '20px', marginBottom: '20px' }} title={disableVerifyEmailOtp ? 'Email is Verified' : 'Verify OTP'} onClick={disableVerifyEmailOtp ? () => true : verifyEmailHandler} />
            )} */}
        </Wrapper>
    );
};

export default ContactVerification;
