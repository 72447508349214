import React, { useContext, useState } from 'react'
import { StateContext } from '../../context/stateContext'
import { BackButton } from '../../components'

const DosDontsPage = () => {
    const {darkMode} = useContext(StateContext)
    const [isTourist, setIsTourist] = useState(true)
    const handleClick = (type) => {
        setIsTourist(type === 'tourist');
    };
  return (
    <div className={`${darkMode ? 'dark-mode' : ''}`}>
        <BackButton text="Do's & Don'ts" />
        <div className='p-3'>
            {/* <p className='fw-400 dark-mode-text'>You are viewing for <span className='text-primary fw-600'>{isTourist ? 'Tourist ' : "Guide "}</span></p> */}
            <div className="button-container">
                <div className={`slider ${isTourist ? 'left' : 'right'}`}></div>
                <button className="toggle-button" style={{color:`${isTourist ? 'white' :'black' }`}} onClick={() => handleClick('tourist')}><span>Tourist</span></button>
                <button className="toggle-button" style={{color:`${!isTourist ? 'white' :'black' }`}} onClick={() => handleClick('guide')}>Guide</button>
            </div>
            <br/>
            {isTourist &&
                <>
                    <h2 className='fw-400 dark-mode-text'>Traveller</h2>
                    <ul className='dark-mode-text'>
                        <li className='my-10'>⨀ Avoid meeting the guide at remote place.</li>
                        <li className='my-10'>⨀ Always prefer to meet the guide at public places.</li>
                        <li className='my-10'>⨀ Try to know more about your guide and the services he could provide.</li>
                        <li className='my-10'>⨀ Giving a tip to guide is your choice of appreciation.</li>
                        <li className='my-10'>⨀ Do share a review to guide about your experiences.</li>
                        <li className='my-10'>⨀ Must communicate with guide over phone call before meeting in-person to avoid confusion & misunderstanding.</li>
                        <li className='my-10'>⨀ Kindly inform the guide if you will be late to reach the meeting point.</li>
                        <li className='my-10'>⨀ Must inform the guide & justify, if you cannot meet due to any reason.</li>
                        <li className='my-10'>⨀ Avoid carrying valuable expensive item on tour.</li>
                        <li className='my-10'>⨀ Ask for permission before taking a picture or recording video.</li>
                        <li className='my-10'>⨀ Always take care of your belongings.</li>
                        <li className='my-10'>⨀ Book a guide by paying 10% of the charges (This gesture will represent, tourist is seriously interested in guide's service).</li>
                        <li className='my-10'>⨀ Don't turn off mobile location.</li>
                        <li className='my-10'>⨀ Avoid discussing sensitive topics & use of slang or idioms.</li>
                        <li className='my-10'>⨀ Always prefer to exchange currency in INR Indian Rupee.</li>
                        <li className='my-10'>⨀ Treat every host (guide) equally & maintain physical boundaries. </li>
                    </ul>
                </>
            }
            {!isTourist &&
                <>
                    <h2 className='fw-400 dark-mode-text'>Guide</h2>
                    <ul className='dark-mode-text'>
                            <li className='my-10'>⨀ Avoid meeting the tourist at remote place.</li>
                            <li className='my-10'>⨀ Always prefer to meet the tourist at public places.</li>
                            <li className='my-10'>⨀ Make sure to discuss all the details of the tour with your guest in advance.</li>
                            <li className='my-10'>⨀ Must communicate with tourist over phone call before meeting in-person to avoidconfusion & misunderstanding.</li>
                            <li className='my-10'>⨀ Don't ask for a tip, instead request them to write a review.</li>
                            <li className='my-10'>⨀ Avoid carrying valuable expensive item on tour.</li>
                            <li className='my-10'>⨀ Always take care of your belongings.</li>
                            <li className='my-10'>⨀ Don't turn off mobile location.</li>
                            <li className='my-10'>⨀ Always reach 15-30 minutes before at the meeting point to make an impression.</li>
                            <li className='my-10'>⨀ Kindly inform the tourist if you will be late to reach the meeting point.</li>
                            <li className='my-10'>⨀ Must inform the tourist & justify, if you cannot meet due to any reason.</li>
                            <li className='my-10'>⨀ Request the traveler to share a review on your profile.</li>
                            <li className='my-10'>⨀ Treat every guest (traveler) equally & maintain physical boundaries.</li>
                            <li className='my-10'>⨀ Don't get impatient with questions & be sensible to an individual.</li>
                            <li className='my-10'>⨀ Avoid discussing sensitive topics & use of slang or idioms.</li>
                            <li className='my-10'>⨀ Ask for permission before taking a picture or recording video.</li>
                            <li className='my-10'>⨀ Always prefer to exchange currency in INR Indian Rupee. </li>
                    </ul>
                </>
            }
            {!isTourist &&
                <>
                    <h1 className='text-primary fw-500'>Advice for City Guide</h1>
                    <p className='my-10 dark-mode-text fs-12 fw-400' style={{fontStyle:"italic"}}>This is to ensure you have the best possible experience while showing travellers around, start by reading these simple tips on how to set up your tours and interact with your future guests.</p>
                    <h2 className='dark-mode-text fs-16 fw-500'>✓ FIRST CONTACT</h2>
                    <p className='my-10 dark-mode-gray-text'>A booking or message request means you have made your first impression as a City Guide. Make sure to
                    keep up and respond quickly, also check out their profile to know more about them.</p>
                    <h2 className='dark-mode-text fs-16 fw-500'>✓ GREAT EXPECTATIONS</h2>
                    <p className='my-10 dark-mode-gray-text'>The travellers who use City2Guide are passionate about exploring the world and are eager to learn
                    more about you and the places you love. So, make their tour worthwhile, learn what your guests
                    expect from you as early as possible and also explain what you can offer them.</p>
                    <h2 className='dark-mode-text fs-16 fw-500'>✓ BE YOURSELF</h2>
                    <p className='my-10 dark-mode-gray-text'>Be open about who you are, what you like, and what kinds of activities you can offer to your guest. If you
                    and your guests are a good fit, they will have more fun on their tour. To save time when communicating,
                    try to put the most important facts about yourself on your profile.</p>
                    <h2 className='dark-mode-text fs-16 fw-500'>✓ PREPARE EVERYTHING</h2>
                    <p className='my-10 dark-mode-gray-text'>Make sure to discuss all the details of the tour with your guest in advance. Knowing the tour duration,
                    booking hours, tour routes, places to visit, timing and possible meeting points will save time and make
                    things run more smoothly.</p>
                    <h2 className='dark-mode-text fs-16 fw-500'>✓ WHAT'S NOT INCLUDED</h2>
                    <p className='my-10 dark-mode-gray-text'>Politely inform your guests of any expenses that are not included in your price, such as transportation,
                    entrance fees or any third-party services.</p>
                    <h2 className='dark-mode-text fs-16 fw-500'>✓ KEEP IN TOUCH</h2>
                    <p className='my-10 dark-mode-gray-text'>Contact your guest, whenever you need to inform them about any changes to your plans.
                    If you couldn’t meet, kindly inform traveller and justify the reason.</p>
                    <h2 className='dark-mode-text fs-16 fw-500'>✓ FIRST IMPRESSIONS</h2>
                    <p className='my-10 dark-mode-gray-text'>Make sure you will be recognized on the day of your tour and set a clear meeting point and time. Don’t
                    hesitate to contact your guests a few hours before meeting time to make sure they are on time and ask
                    whether they need help getting to the meeting point. Always prefer to meet the traveller at public places.</p>
                    <h2 className='dark-mode-text fs-16 fw-500'>✓ INDIVIDUAL OR GROUP?</h2>
                    <p className='my-10 dark-mode-gray-text'>Even if you communicate with one person you may find out that you will be showing around a couple or
                    even a group of people. Make sure you discuss this beforehand and confirm you are okay with the
                    number of guests that will be on the tour.</p>
                    <h2 className='dark-mode-text fs-16 fw-500'>✓ KEEPING IT CLASSY</h2>
                    <p className='my-10 dark-mode-gray-text'>Be diligent and don’t be late. If you happen to be behind schedule, please call your guest and let him or
                    her know. Have your phone turned on and charged at all times on the day of your tour.</p>
                    <h2 className='dark-mode-text fs-16 fw-500'>✓ KEEPING IT FUN</h2>
                    <p className='my-10 dark-mode-gray-text'>Relax! When meeting your guests, make sure you are rested, in a good mood and eager to start having
                    fun on the tour.</p>
                    <h2 className='dark-mode-text fs-16 fw-500'>✓ ALWAYS KEEP TABS</h2>
                    <p className='my-10 dark-mode-gray-text'>We strongly recommend to communicate with your guest via City2Guide to make sure there is a record
                    of your interaction in case of any disputes that may arise.</p>
                    <h2 className='dark-mode-text fs-16 fw-500'>✓ NEED HELP?</h2>
                    <p className='my-10 dark-mode-gray-text'>Contact us if you experience any trouble or have any questions about your booking before, during or
                    even after your tour. We are always ready to help. </p>
                </>
            }
        </div>
        
    </div>
  )
}

export default DosDontsPage